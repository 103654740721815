<div *ngIf="!loadCompleted" class="row loading">
    Data is Loading
    <div class="la-ball-spin-clockwise">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
</div>

<div *ngIf="loadCompleted && validPlatform" class="reports" [ngClass]="{
    'google': reportMetrics.Platform === 'Google',
    'meta': reportMetrics.Platform === 'Meta'
}">
    <div class="left">
        <h3 style="text-align: center;">{{ campaignName }}</h3>

        <div class="reportForm" style="padding: 18px;">
            <form [formGroup]="reportForm" (ngSubmit)="updateReport()">
                <div>
                    <div class="formGroup dateSelect">
                        <div class="form groupHeader">
                            <label>Please select report period:</label>
                        </div>
                        <div class="form dropdown">
                            <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)" #t>
                                <option selected disabled>Please select period.</option>
                                <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods" [selected]="selectedPeriod==p.key">
                                    {{ p.key }}
                                </option>
                            </select>
                        </div>
                        <div class="dateRange">
                            <div class="form dateInput">
                                <label class="sr-only" for="startDate">Start</label>
                                <input type="date" [readonly]="datesReadonly" formControlName="startDate"
                                class="form-control form-date date-custom" [ngClass]="{ 'is-invalid': reportForm.controls.startDate.errors }" />
                                <div *ngIf="reportForm.controls.startDate.errors" class="invalid-feedback">
                                <div *ngIf="reportForm.controls.startDate.errors.required">Please select period.</div>
                                </div>
                            </div>

                            <div class="form dateInput">
                                <span style="margin: 0 6px;">&mdash;</span>
                            </div>

                            <div class="form dateInput">
                                <label class="sr-only" for="stopDate">End</label>
                                <input type="date" [readonly]="datesReadonly" formControlName="stopDate"
                                class="form-control form-date date-custom" [ngClass]="{ 'is-invalid': reportForm.controls.stopDate.errors }" />
                                <div *ngIf="reportForm.controls.stopDate.errors" class="invalid-feedback">
                                <div *ngIf="reportForm.controls.stopDate.errors.required">Please select period.</div>
                                </div>
                            </div>
                      </div>
                    </div>
                    <div class="formGroup">
                        <div class="form groupHeader">
                            <label class="mb-2">Show Sections:</label>
                        </div>
                        <div class="form checkbox" fxLayout="row" fxLayoutAlign="start center">
                            <div *ngIf="reportMetrics.Platform === 'Google' || reportMetrics.Platform === 'Meta'">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showLeadsByDay" id="showLeadsByDay">
                                <label class="form-check-label" for="showLeadsByDay">Leads by Day Chart</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showDevices" id="showDevices">
                                <label class="form-check-label" for="showDevices">Devices</label>
                            </div>
                            <div *ngIf="reportMetrics.Platform === 'Google'" class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showKeywords" id="showKeywords">
                                <label class="form-check-label" for="showKeywords">Keywords</label>
                            </div>
                            <div *ngIf="reportMetrics.Platform === 'Meta'" class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showAds" id="showAds">
                                <label class="form-check-label" for="showAds">Ads</label>
                            </div>
                            <div *ngIf="reportMetrics.Platform === 'Meta'" class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showDemographics" id="showDemographics">
                                <label class="form-check-label" for="showDemographics">Demographics</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showCaculator" id="showCaculator">
                                <label class="form-check-label" for="showCaculator">Calculate Sales Funnel</label>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="reportForm.get('showCaculator')?.value" class="formGroup caculator">
                        <div class="form groupHeader">
                            <label>Enter Values for This Date Range:</label>
                        </div>
                        <div class="form inputValues">
                            <label for="Booked">Booked</label>
                            <input type="number" formControlName="Booked"
                            class="form-control form-input" placeholder="Enter # of Leads who boooked" />
                        </div>
                        <div class="form inputValues">
                            <label for="Show">Show</label>
                            <input type="number" formControlName="Show"
                            class="form-control form-input" placeholder="Enter # of Leads who showed" />
                        </div>
                        <div class="form inputValues">
                            <label for="Sold">Sold</label>
                            <input type="number" formControlName="Sold"
                            class="form-control form-input" placeholder="Enter # of Leads who purchased" />
                        </div>
                    </div>
                    <div class="formGroup">
                        <div class="form submit">
                            <button type="submit" class="btn btn-primary mb-2">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="right">
        <div class="nodata" *ngIf="reportMetrics.noData; else showSummary">
            <h3 style="text-align: center;">{{ this.reportMetrics.noData }}</h3>
        </div>
        <ng-template #showSummary>
            <div class="summary">
                <h3 style="text-align: center;">Marketing Summary</h3>
                <div class="blocks" style="margin-top: 25px;">
                    <div class="kpi">
                        <h3>{{ reportMetrics.Cost | currency }}</h3>
                        Cost 
                    </div>
                    <div class="kpi">
                        <h3>{{ reportMetrics.Impressions | number }}</h3>
                        Impressions 
                    </div>
                    <div *ngIf="reportMetrics.Platform === 'Meta'" class="kpi">
                        <h3>{{ reportMetrics.Cpm | currency }}</h3>
                        Cost Per 1,000 Impressions (CPM) 
                    </div>
                    <div class="kpi">
                        <h3>{{ reportMetrics.Clicks | number }}</h3>
                        Clicks 
                    </div>
                    <div *ngIf="reportMetrics.Platform === 'Google'" class="kpi">
                        <h3>{{ reportMetrics.Cpc | currency }}</h3>
                        Cost Per Click (CPC)
                    </div>
                </div>
                <div class="blocks" style="margin-top: 25px;">
                    <div class="kpi">
                        <h3>{{ reportMetrics.Ctr | percent:'1.2-2' }}</h3>
                        Click Through Rate (CTR) 
                    </div>
                    <div class="kpi">
                    <h3>{{ reportMetrics.Leads | number }}</h3>
                    Leads 
                    </div>
                    <div class="kpi">
                    <h3>{{ reportMetrics.Lsr | percent:'1.2-2' }}</h3>
                    Lead Submission Rate (LSR) 
                    </div>
                    <div class="kpi">
                    <h3>{{ reportMetrics.Cpl | currency }}</h3>
                    Cost Per Lead (CPL) 
                    </div>
                </div>
            </div>
        </ng-template>
        <section *ngIf="reportForm.get('showCaculator')?.value">
            <h3 style="text-align: center;">Sales Funnel</h3>
            <div class="summary">
                <div class="blocks" style="margin-top: 25px;">
                    <div *ngIf="reportMetrics.BookingPercent" class="cac">
                        <h3>{{ reportMetrics.BookingPercent | percent:'1.2-2' }}</h3>
                        Lead to Book %
                    </div>
                    <div *ngIf="reportMetrics.BookToShowPercent" class="cac">
                        <h3>{{ reportMetrics.BookToShowPercent | percent:'1.2-2' }}</h3>
                        Book to Show %
                    </div>
                    <div *ngIf="reportMetrics.LeadToShowPercent" class="cac">
                        <h3>{{ reportMetrics.LeadToShowPercent | percent:'1.2-2' }}</h3>
                        Lead to Show %
                    </div>
                </div>
                <div class="blocks" style="margin-top: 25px;">
                    <div *ngIf="reportMetrics.LeadToSoldPercent" class="cac">
                        <h3>{{ reportMetrics.LeadToSoldPercent | percent:'1.2-2' }}</h3>
                        Lead to Sold %
                    </div>
                    <div *ngIf="reportMetrics.BookToSoldPercent" class="cac">
                        <h3>{{ reportMetrics.BookToSoldPercent | percent:'1.2-2' }}</h3>
                        Book to Sold %
                    </div>
                    <div *ngIf="reportMetrics.ShowToSoldPercent" class="cac">
                        <h3>{{ reportMetrics.ShowToSoldPercent | percent:'1.2-2' }}</h3>
                        Show to Sold %
                    </div>
                </div>
                <div class="blocks" style="margin-top: 25px;">
                    <div *ngIf="reportMetrics.CostPerBooking" class="cac">
                        <h3>{{ reportMetrics.CostPerBooking | currency }}</h3>
                        Cost Per Booking
                    </div>
                    <div *ngIf="reportMetrics.CostPerShow" class="cac">
                        <h3>{{ reportMetrics.CostPerShow | currency }}</h3>
                        Cost Per Show
                    </div>
                </div>
                <div class="blocks" style="margin-top: 25px;">
                    <div *ngIf="reportMetrics.CostPerAcquisition" class="cac">
                        <h3>{{ reportMetrics.CostPerAcquisition | currency }}</h3>
                        Cost Per Acquisition
                    </div>
                </div>
            </div>
        </section>

        <section *ngIf="reportForm.get('showLeadsByDay')?.value && reportMetrics.Leads > 0" class="leadsChart">
            <h3 style="text-align: center;">Leads by Day</h3>
            <p style="text-align: center; font-size: 0.9em;">(Will Show a Maximum of the Last 2 Months)</p>
            <div class="background">
                <ul class="barGraph">
                    <li *ngFor="let lead of recentLeads; let i = index"
                        [ngStyle]="{
                            'grid-row': ((getMaxConversions()*36) - (lead.Conversions*36) + (getMaxConversions()*3)) + ' / span ' + ((lead.Conversions*36) + 1),
                            'grid-column': (i + 1)
                        }"
                        title="{{ lead.Date | date:'MMM d' }}">
                        <span class="leadNum">{{ lead.Conversions }}</span>
                        <div class="bar" [ngClass]="{ 'empty': lead.Conversions === 0 }"></div>
                        <ng-container *ngIf="recentLeads.length <= 30; else shortDate">
                            <span class="leadDate">{{ lead.Date | date:'MMM d' }}</span>
                        </ng-container>
                        <ng-template #shortDate>
                            <span class="leadDate">{{ lead.Date | date:'d' }}</span>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </section>

        <section *ngIf="reportMetrics.AllCampaigns && reportMetrics.AllCampaigns.length > 0">
            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="allCampaignsColumns" 
                [iData$]="reportMetrics.AllCampaigns"
                [iTotalCount]="reportMetrics.AllCampaigns.length"
                [iShowFooter]="reportMetrics.AllCampaigns.length > 25"
                [iReportTitle]="'Campaigns'"
            >
            </app-report-datatable>
        </section>

        <section *ngIf="reportForm.get('showDevices')?.value && reportMetrics.Devices && reportMetrics.Devices.length > 0">
            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="devicesColumns" 
                [iData$]="reportMetrics.Devices"
                [iTotalCount]="reportMetrics.Devices.length"
                [iShowFooter]="reportMetrics.Devices.length > 25"
                [iReportTitle]="'Devices'"
            >
            </app-report-datatable>
        </section>

        <section *ngIf="reportForm.get('showKeywords')?.value && reportMetrics.Keywords && reportMetrics.Keywords.length > 0">
            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="googleKeywordsColumns" 
                [iData$]="reportMetrics.Keywords"
                [iTotalCount]="reportMetrics.Keywords.length"
                [iShowFooter]="reportMetrics.Keywords.length > 25"
                [iReportTitle]="'Keywords'"
            >
            </app-report-datatable>
        </section>

        <section *ngIf="reportForm.get('showAds')?.value && reportMetrics.Ads && reportMetrics.Ads.length > 0">
            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="adsColumns" 
                [iData$]="reportMetrics.Ads"
                [iTotalCount]="reportMetrics.Ads.length"
                [iShowFooter]="reportMetrics.Ads.length > 25"
                [iReportTitle]="'Ads'"
            >
            </app-report-datatable>
            <!--
            <tr *ngFor="let ad of reportMetrics.Ads">
                <td *ngIf="reportMetrics.Platform === 'Google'">
                    <strong>{{ ad.HeadlinePart1 }}{{ ad.HeadlinePart2 }}{{ ad.HeadlinePart3 }}</strong><br />
                    {{ ad.Desc1 }}{{ ad.Desc2 }}
                </td>
                <td *ngIf="reportMetrics.Platform === 'Meta'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <div style="min-width: 64px"><img [src]="ad.ThumbnailUrl" alt="Thumbnail" width="64px"></div>
                        <div>
                            <strong>{{ ad.Title }}</strong><br />
                            <span [innerHTML]="formatAdBody(ad.Body)"></span>
                        </div>
                    </div>
                </td>
            </tr>
            -->
        </section>

        <section *ngIf="reportForm.get('showDemographics')?.value && reportMetrics.AgeGenderDemographics && reportMetrics.AgeGenderDemographics.length > 0">
            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="metaDemographicsColumns" 
                [iData$]="reportMetrics.AgeGenderDemographics"
                [iTotalCount]="reportMetrics.AgeGenderDemographics.length"
                [iShowFooter]="reportMetrics.AgeGenderDemographics.length > 25"
                [iReportTitle]="'Demographics'"
            >
            </app-report-datatable>

            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="metaAgeDemographicsColumns" 
                [iData$]="reportMetrics.AgeDemographics"
                [iTotalCount]="reportMetrics.AgeDemographics.length"
                [iShowFooter]="reportMetrics.AgeDemographics.length > 25"
                [iReportTitle]="'Age Demographics'"
            >
            </app-report-datatable>

            <app-report-datatable 
                [iSortColumn]="'Leads'"
                [iSortDirection]="'desc'"
                [iColumns]="metaGenderDemographicsColumns" 
                [iData$]="reportMetrics.GenderDemographics"
                [iTotalCount]="reportMetrics.GenderDemographics.length"
                [iShowFooter]="reportMetrics.GenderDemographics.length > 25"
                [iReportTitle]="'Gender Demographics'"
            >
            </app-report-datatable>
        </section>
    </div>
</div>

<div *ngIf="!validPlatform" class="row">
    <div class="col-md-12">
        <div class="card" style="background-color: #e9e9ef !important;">
          <div class="card-body">
            <div class="details_top">
                <h3 style="text-align: center;">Reports are not available for this Platform at this time.</h3>
            </div>
        </div>
    </div>
</div>
