import { Pipe, PipeTransform } from '@angular/core';
import { NgbPaginationNumber } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from 'underscore';
import { CAMPAIGN_STAGES, COUPON_TYPES, ORDER_STATUS, PRODUCT_FAMILY, PRODUCT_TYPES } from '../core/helpers/constants';
import { IRole, IRoleCreation } from '../core/models/auth.models';

@Pipe({ name: 'P_StringSplit' })
export class P_StringSplit implements PipeTransform {
  transform(stringToSplit: string, splitChar: string, indexToReturn = 0): string {
    return stringToSplit.split(splitChar)[indexToReturn];
  }
}

@Pipe({ name: 'P_AppDateFormat', pure: false })
export class P_AppDateFormat implements PipeTransform {
  transform(dateToFormat: Date, format = '', toLocal = false): string {
    let r: any = '';
    if (dateToFormat == null)
      return r;
    r = moment(dateToFormat);
    if (toLocal) {
      r = moment.utc(dateToFormat);
      r = r.local();
    }
    if (format) {
      r = r.format(format);
    }
    else
      r = r.format("MM/DD/YYYY");
    return r;
  }
}

@Pipe({ name: 'P_CampaignStageTitle', pure: false })
export class P_CampaignStageTitle implements PipeTransform {
  transform(id: any): string | undefined {
    if (id == null)
      return '';
    return CAMPAIGN_STAGES.find(x => x.value === id)?.title
  }
}

@Pipe({ name: 'P_CouponTypesTitle', pure: false })
export class P_CouponTypesTitle implements PipeTransform {
  transform(id: any): string | undefined {
    if (id == null)
      return '';
    return COUPON_TYPES.find(x => x.value === id)?.title
  }
}

@Pipe({ name: 'P_CouponExpiry', pure: false })
export class P_CouponExpiry implements PipeTransform {
  transform(dateToFormat: Date, format = '', toLocal = false): string {
    let r: any = '';
    if (dateToFormat == null)
      return "No Expiry";
    r = moment(dateToFormat);
    if (toLocal) {
      r = moment.utc(dateToFormat);
      r = r.local();
    }
    if (format) {
      r = r.format(format);
    }
    else
      r = r.format("MM/DD/YYYY");
    return r;
  }
}

@Pipe({ name: 'P_Boolean', pure: false })
export class P_Boolean implements PipeTransform {
  transform(value: any): string | undefined {
    if (value == null)
      return '';
    return value === true ? 'Yes' : 'No'
  }
}

@Pipe({ name: 'P_RolesType_Filter', pure: false })
export class P_RolesType_Filter implements PipeTransform {
  transform(value: Array<IRole>, type: number): Array<IRole> | undefined {
    let r: Array<IRole> = [];
    if (value == null || value.length == 0)
      return r;
    return value.filter(x => x.type === type);
  }
}

@Pipe({ name: 'P_ProducType', pure: false })
export class P_ProducType implements PipeTransform {
  transform(type: number): string | undefined {
    switch (type) {
      case PRODUCT_TYPES.SimpleProduct.code:
        return PRODUCT_TYPES.SimpleProduct.title;
      case PRODUCT_TYPES.SimpleSubscription.code:
        return PRODUCT_TYPES.SimpleSubscription.title;
      case PRODUCT_TYPES.VariableSubscription.code:
        return PRODUCT_TYPES.VariableSubscription.title;
      default:
        return 'Bundle'
    }
  }
}

@Pipe({ name: 'P_ProducFamily', pure: false })
export class P_ProducFamily implements PipeTransform {
  transform(type: number): string {
    switch (type) {
      case PRODUCT_FAMILY.Bundle:
        return "Bundle";
      case PRODUCT_FAMILY.AdSpend:
        return "Ad Spend";
      case PRODUCT_FAMILY.DigitalMarketing:
        return "Digital Marketing";
      case PRODUCT_FAMILY.CampaignConsulting:
        return "Campaign Consulting";
      case PRODUCT_FAMILY.HostedWebsite:
        return "Hosted Website";
      case PRODUCT_FAMILY.SEOProgram:
        return "SEO Program";
      case PRODUCT_FAMILY.SocialMedia:
        return "Social Media";
      case PRODUCT_FAMILY.AddPlatform:
        return "Add Platform";
      default:
        return "";
    }

  }
}

@Pipe({ name: 'P_CallbackPipe', pure: false })
export class P_CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}

@Pipe({ name: 'P_OrderStatus', pure: false })
export class P_OrderStatus implements PipeTransform {
  transform(id: any): string | undefined {
    if (id == null)
      return '';
    return ORDER_STATUS.find(x => x.value === id)?.title
  }
}

@Pipe({ name: 'P_FreqFromDuration', pure: false })
export class P_FreqFromDuration implements PipeTransform {
  transform(dur: string | undefined): string | undefined {
    switch (dur) {
      case 'day':
        return '';
      case 'week':
        return 'day';
      case 'month':
        return 'week';
      case 'year':
        return 'month';
      default:
        return ''
    }
  }
}

@Pipe({ name: 'P_Ordinal', pure: false })
export class P_Ordinal implements PipeTransform {
  transform(value: number): string {
    if (value === 1) {
      return value + 'st';
    } else if (value === 2) {
      return value + 'nd';
    } else if (value === 3) {
      return value + 'rd';
    } else {
      return value + 'th';
    }
  }
}

@Pipe({ name: 'P_RolesName_Filter', pure: false })
export class P_RolesName_Filter implements PipeTransform {
  transform(code: number): string {
    switch (code) {
      case 1:
        return 'Admin';
      case 2:
        return "Tech Services";
      case 3:
        return "DMS";
      case 4:
        return "Shop Manager";
      case 5:
        return "Company Owner";
      case 6:
        return "Marketing Manager";
      case 7:
        return "Client Owner";
      case 8:
        return "Employee";
      case 9:
        return "Subscription Employee";
      case 10:
        return "Sales Rep";
      case 12:
        return "Staff";
      default:
        return '';
    }

  }
}

@Pipe({ name: 'cleanupCampaign' })
export class CleanupCampaignPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.replace(' FB', '').replace(' TT', '');
  }
}

@Pipe({ name: 'NumberPipe', pure: false })
export class NumberPipe implements PipeTransform {
  transform(value: number, digits: number[] = [0]): string {
    let fractionDigits = Number(digits[0]);
    if (fractionDigits > 0) {
      return value.toLocaleString('en-US', { style:'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
    }
    return value.toLocaleString('en-US');
  }
}

@Pipe({
  name: 'PercentPipe', pure: false
})
export class PercentPipe implements PipeTransform {
  transform(value: number, digits: number[] = [2]): string {
    return (value * 100).toFixed(digits[0]) + '%';
  }
}