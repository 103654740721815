import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { WidgetModule } from './widget/widget.module';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { NgbDropdownModule, NgbModule, NgbNavModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddClientModal } from './modals/add-client/add-client.component';
import { DataFeedService } from './api/datafeed.service';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AddNoteModal } from './modals/add-note/add-note.component';
import { AddCampaignCheckModal } from './modals/add-campaign-check/add-campaigncheck.component';
import { P_AppDateFormat, P_Boolean, P_CampaignStageTitle, P_FreqFromDuration, P_OrderStatus, P_ProducFamily, P_ProducType, P_RolesType_Filter, P_Ordinal, P_RolesName_Filter } from './shared.pipe';
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { AssignTagModal } from './modals/assign-tag/assign-tag.component';
import { DatatableModule } from './components/datatable/datatable.module';
import { SpecialDatatableModule } from './components/special-datatable/special-datatable.module';
import { CampaignDatatableModule } from './components/campaign-datatable/campaign-datatable.module';
import { ReportDatatableModule } from './components/report-datatable/report-datatable.module';
import { TasksDatatableModule } from './components/tasks-datatable/tasks-datatable.module';
import { AddTaskModal } from './modals/add-task/add-task.component';
import { AddUserModal } from './modals/add-user/add-user.component';
import { AddLeadModal } from './modals/add-lead/add-lead.component';
import { AddCompanyModal } from './modals/add-company/add-company.component';
import { ProductsModalComponent } from './modals/products-modal/products-modal.component';
import { TagsTabComponent } from './components/tags-tab/tags-tab.component';
import { RouterModule } from '@angular/router';
import { AddPaymentMethodModal } from './modals/add-payment-method/add-payment-method.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ArchwizardModule } from 'angular-archwizard';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AddOrderModal } from './modals/add-order/add-order.component';
import { AddOrderToSubscriptionModal } from './modals/add-order/add-order-subscription.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ProcessOrderPaymentModal } from './modals/process-order-payment/process-order-payment.component';
import { ProductCheckoutComponent } from '../pages/shop/product-checkout/product-checkout.component';
import { MatIconModule } from '@angular/material/icon';
import { DragDrop, DragDropModule } from '@angular/cdk/drag-drop';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    PagetitleComponent,
    AddClientModal,
    AddNoteModal,
    AddCampaignCheckModal,
    AssignTagModal,
    AddTaskModal,
    AddUserModal,
    AddLeadModal,
    AddCompanyModal,
    ProductsModalComponent,
    P_AppDateFormat,
    P_CampaignStageTitle,
    P_Boolean,
    P_RolesType_Filter, 
    P_ProducType,
    P_Ordinal,
    P_ProducFamily,
    P_OrderStatus,
    P_AppDateFormat,
    P_FreqFromDuration,
    P_RolesName_Filter,
    TagsTabComponent,
    AddPaymentMethodModal,
    AddOrderModal,
    AddOrderToSubscriptionModal,
    ProductCheckoutComponent,
    ProcessOrderPaymentModal,
  ],
  imports: [
    CommonModule,
    RouterModule,
    WidgetModule,
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    DatatableModule,
    SpecialDatatableModule,
    CampaignDatatableModule,
    ReportDatatableModule,
    TasksDatatableModule,
    UiSwitchModule, 
    NgbNavModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    FlatpickrModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FeatherModule.pick(allIcons),
    FlexLayoutModule,
    ArchwizardModule,
    NgxDropzoneModule,
    MatIconModule,
    DragDropModule
  ],
  exports: [
    PagetitleComponent,
    TagsTabComponent,
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbNavModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    UiSwitchModule, 
    FlatpickrModule,
    NgxMaskModule,
    P_AppDateFormat,
    P_FreqFromDuration,
    P_CampaignStageTitle,
    P_Boolean,
    P_RolesType_Filter,
    P_RolesName_Filter,
    P_ProducType,
    P_Ordinal,
    P_ProducFamily,
    P_OrderStatus,
    FeatherModule,
    FlexLayoutModule,
    ArchwizardModule,
    ProductCheckoutComponent,
    ProcessOrderPaymentModal
  ],
  providers: [DataFeedService]
})
export class SharedModule { }
