import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportViewComponent } from './report-view/report-view.component';
import { ReportsMainComponent } from './reports-main/reports-main.component';
import { ReportsRawCampaignsDataComponent } from './reports-raw-campaigns-data/reports-raw-campaigns-data.component';
import { ReportsRollupCompanyCampaignsComponent } from './reports-rollup-company-main/reports-rollup-company-main.component';
import { ReportsRollupMainComponent } from './reports-rollup-main/reports-rollup-main.component';
import { ReportsBrandDashboardComponent } from './reports-brand-report/reports-brand-report.component';
import { ReportsSimpleCampaignsComponent } from './reports-simple-campaigns/reports-simple-campaigns.component';
import { WeeklyReportsComponent } from './weekly-reports/weekly-reports.component';

const routes: Routes = [
   {
      path: '',
      component: ReportsMainComponent,
      children: [
         {
            path: '',
            redirectTo: 'campaign-report',
            pathMatch: 'full'
         },
         {
           path: 'campaign-report',
           component: ReportsSimpleCampaignsComponent
         },
         {
           path: 'rollup-report',
           component: ReportsRollupMainComponent
         },
         {
           path: 'weekly-reports',
           component: WeeklyReportsComponent
         },
         {
           path: 'brand-report',
           component: ReportsBrandDashboardComponent
         }
       ]
   },
   {
      path: 'view-report/:id',
      component: ReportViewComponent
   },
   {
      path: 'rollup-campaigns/:id',
      component: ReportsRollupCompanyCampaignsComponent
   },
   {
      path: 'rollup-report/viewer/:id/:orderId',
      component: ReportsRollupCompanyCampaignsComponent
   }, 
   {
      path: 'raw-campaigns-data',
      component: ReportsRawCampaignsDataComponent
   },
   {
      path: 'brand-report',
      component: ReportsBrandDashboardComponent
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class ReportsRoutingModule { }
