import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ReportsApiService } from '../reports.apiservice';
import { IRollupReportDataResponse } from '../../../core/models/reports.models';

@Component({
  selector: 'app-reports-rollup-report-view',
  templateUrl: './reports-rollup-report-view.component.html',
  styleUrls: ['./reports-rollup-report-view.component.scss']
})
export class ReportsRollupReportViewComponent implements OnInit {

  @Input() iRollupReportDataResponse: IRollupReportDataResponse | null = null;
  @Input() iReportFormat: string = '';

  constructor(private router: Router, private domSanitizer: DomSanitizer, private reportsService: ReportsApiService,) {
  }

  loadCompleted = false;
  ngOnInit(): void {
    if (this.iRollupReportDataResponse) {
      this.reportsService.getRollupReport(this.iRollupReportDataResponse?.orderId, this.iReportFormat)
      .subscribe(responseUrl => {
        console.log(responseUrl);
        document.querySelector('iframe')?.setAttribute("src", responseUrl);
        //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(responseUrl.toString())
      });
      //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.reportsService.getRollupReport(this.iRollupReportDataResponse?.orderId));
      // this.reportsService.getRollupReportCompleteDate(this.iRollupReportDataResponse?.orderId)
      // .subscribe(res => {
      //   console.log(res);
      //   this.reportData = res;
      //   // this.reportData = this.domSanitizer.bypassSecurityTrustHtml(res).toString();
         this.loadCompleted = true;
      // });
    }
  }

  onFrameLoadComplete() {
    // console.log('this.loadCompleted: ', this.loadCompleted);
    if (this.iRollupReportDataResponse) {
      this.loadCompleted = true;
    }
  }

  reportUrl = '' as SafeResourceUrl;
  // getReportUrl(): SafeResourceUrl {
  //   var uri = '';
  //   if (this.iRollupReportDataResponse) {
  //     let params = {
  //       orderId: this.iRollupReportDataResponse?.orderId,
  //       reportFormat: this.iReportFormat
  //     };

  //     var uri = environment.baseUrl + this.router.createUrlTree(['/Dashboard/completed-rollup-report'], { queryParams: params })
  //   }

  //   return this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
  // }

}
