import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IWidget } from '../../core/models/widget.model';
import { AppService } from '../../store/app.service';
import { Options, SortableEvent } from 'sortablejs';
import { IPermission, IUser } from '../../core/models/auth.models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as util from '../../core/helpers/utility';
import { UtilityService } from '../../core/helpers/utility.service';
import { IColumn, LoadDataFromServer } from '../../shared/components/datatable/datatable.model';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../core/helpers/constants';
import { ITask } from '../../core/models/tasks.models';
import { TasksApiService } from '../tasks/tasks.apiservice';
import { TasksService } from '../tasks/tasks.service';
import { DataFeedService } from '../../shared/api/datafeed.service';
import { ICompany } from '../../core/models/datafeed.models';
import { ReportsApiService } from '../reports/reports.apiservice';
import { ICampaign } from '../../core/models/campaigns.model';
import { IRollupReportDataResponse } from '../../core/models/reports.models';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { createEmptyGuid } from '../../core/helpers/utility';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IReportingPeriod } from '../../core/models/reporting.models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 *  Dashboard Component
 */
export class DashboardComponent implements OnInit {

  ROLES = ROLES;
  activeUser: IUser = {} as IUser;
  public userRoles: Array<number> = [];
  private loadEvent!: LoadDataFromServer;
  doWhat: IPermission = {} as IPermission;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  id: any = '';
  token: string = '';
  isAdmin: boolean = false;
  externalUser: boolean = false;

  // vague names
  columns: Array<IColumn> = [];
  dataTotalCount: number = 0;
  iframeSrc: SafeResourceUrl= '';

  // change this!
  getHeight: string = '85vh';

  // Tabs
  tabNumber = 1;
  tabs = ['Tasks', 'Dashboard', 'Widgets'];
  activeTab = this.tabs[1];
  active: number = 1;

  // Widgets
  widgets!: IWidget[];

  // Data Structures
  platforms: Array<string> = [];
  todoTasksData: Array<ITask> = new Array<ITask>();
  companiesData: Array<ICompany> = [];
  campValues: Array<{ state: string; count: number; }> = [];
  // leadValues: Array<{ state: string; count: number; }> = [];

  // Marketing Manager Dash
  tooManyCompanies = false;
  rollupReportDataResponse: IRollupReportDataResponse | null = null;
  reportformsubmitted = false;
  companyId = '';
  companyData = {} as ICompany;
  filteredCampaignsData = new BehaviorSubject<Array<ICampaign>>([]);
  bShowSelect$ = new BehaviorSubject(false);
  loadCompleted = false;
  dataLoadCompleted = false;
  reportPeriods: Array<IReportingPeriod> = [];
  @Input() iReportFormat: string = '';
  reportUrl = '' as SafeResourceUrl;
  totalLeadCount: number = 0;
  totalClientCount: number = 0;
  totalCampaignCount: number = 0;
  totalActiveCampaignCount: number = 0;
  totalFacebookCampaignCount: number = 0;
  totalGoogleCampaignCount: number = 0;
  interval: any;

  reportForm: FormGroup = this.fb.group({
    key: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    stopDate: [null, [Validators.required]],
    platforms: ['All', [Validators.required]],
    reportFormat: ['html', [Validators.required]],
    aggregation: ['All', [Validators.required]],
  });

  sortableOptions: Options = {
    group: 'test',
    handle: '.draghandler',
    onUpdate:(event) =>  this.onWidgetDrop(event),
    dataIdAttr: 'id'
  };

  constructor(private appService: AppService, 
    private sanitizer: DomSanitizer, 
    private tasksService: TasksService,
    private dataFeedService: DataFeedService,
    private reportsService: ReportsApiService,
    private taskApiService: TasksApiService,
    private fb: FormBuilder,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    public uService: UtilityService) {  

    this.userRoles = [...this.uService.getUserRoles()];
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.activeUser = u;
      }
    )

    this.columns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.columns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.columns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.columns.push({ title: 'Due Date', field: 'dueDate', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] } as IColumn);
    this.columns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, searchable: true, visible: (this.userRoles.includes(ROLES.Admin.Code)) || this.activeUser.type === ROLES_TYPE.Internal } as IColumn);
  }

  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.selectedReportPeriod == 'Custom'); }
  get completed(): Array<ICampaign> { return this.rollupReportDataResponse?.campaigns.filter(f => f.bundle == "Faulted" || f.bundle == "Completed") ?? []; }
  get totalCompleted() { return this.completed.length; }
  get totalCampaigns() { return this.rollupReportDataResponse?.campaigns ? this.rollupReportDataResponse?.campaigns?.length : 0; }
  get combinedStatus() { return this.totalCampaigns == 0 ? 0 : 100 * this.totalCompleted / this.totalCampaigns; };
  get isEmptyCompanyGuid() { return this.companyId == createEmptyGuid(); };
  get aggregation() { return this.reportForm.get('aggregation') as FormControl; }
  get formPlatforms() { return this.reportForm.get('platforms') as FormControl; }
  get startDate() { return this.reportForm.get('startDate') as FormControl; }
  get stopDate() { return this.reportForm.get('stopDate') as FormControl; }
  get reportFormat() { return this.reportForm.get('reportFormat') as FormControl; }
  get key() { return this.reportForm.get('key') as FormControl; }

  ngOnInit(): void {
    this.token = util.getAuthToken();
    this.id = this.activeUser.id;

    if (this.userRoles.includes(1) || this.userRoles.includes(2) || this.userRoles.includes(10)) {
      if (this.userRoles.includes(1) || this.userRoles.includes(2)) {
        this.activeTab = this.tabs[0];
        this.tabNumber = 0;
        this.isAdmin = true;
        this.tabs = ['Tasks', 'Sales Pipeline', 'DMS Dash', 'Widgets', 'Manager Dash'];
      }
      // Bypass security check for this URL
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://prospects.reach.marketing/inside/?authToken=${this.token}&userId=${this.id}&role=${this.userRoles[0]}`);
    }
    else if (this.userRoles.includes(3)) {
      // Bypass security check for this URL
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dms.reach.marketing/testgrounds/?authToken=${this.token}&userId=${this.id}`);
    }
    else if (this.userRoles.includes(4) || this.userRoles.includes(12)) {
      this.tabs = ['Tasks', 'Widgets'];
      this.activeTab = this.tabs[0];
    }
    else {
      this.externalUser = true;
    }

    this.appService.setPageTitle$('');
    this.appService.getWidgets$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IWidget[]) => {
        this.widgets = response.sort((a, b) => a.order - b.order);
      });
    this.appService.fetchWidgets();

    if (!this.externalUser) {
      this.appService.getAllTasks$()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.todoTasksData = response.data;
          this.dataTotalCount = response.totalCount;
      });

    this.appService.getForceLoadingTasks()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: Boolean) => {
        if (res) {
          this.loadData();
          this.appService.setForceLoadingTasks(false);
        }
    })

    this.tasksService.onSave.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    this.tasksService.onDelete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    this.tasksService.onMarkAsComplete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    }
    if (this.externalUser) {
      this.viewAllMMDash();
    }
  }

  selectTab(tab: string) {
    this.activeTab = tab;
    this.tabNumber = this.tabs.indexOf(tab);
    if (this.isAdmin) {
      if (this.activeTab === 'DMS Dash') {
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dms.reach.marketing/testgrounds/?authToken=${this.token}&userId=${this.id}`);
      }
      else {
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://prospects.reach.marketing/inside/?authToken=${this.token}&userId=${this.id}&role=${this.userRoles[0]}`);
      }
      if (this.activeTab === 'Manager Dash') {
        this.viewAllMMDash();
      }
    }
  }

  onCheckBoxChange(params: any) {
    this.taskApiService.markTask({ id: params.data.id, isCompleted: params.evt.currentTarget.checked })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.loadData();
      })
  }

  onWidgetDrop(event: SortableEvent) {
    const widgetId = event.item.id;
    const newOrder = event.newIndex! + 1;
    this.appService.reorderWidget(widgetId, newOrder);
  }

  getLoadingParameters() {
    var yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() + (offset*60*3429))
    let todayAtUserComputer = yourDate.toISOString().split('T')[0];
    return {
      savedSearchId: null,
      showOnlyMyTasks: true,
      dueDate: 'Overdue',
      completion: 'ToDo',
      todayAtUserComputer,
      ...this.loadEvent
    }
  }

  editRow(evt: any) {
    evt.allTasksloadingParameters = this.getLoadingParameters();
    this.tasksService.openEditTaskModal(evt);
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    if (!this.externalUser) {
      this.loadData();
    }
  }

  deleteRows(evt: any) {
    this.taskApiService.deleteTask(evt.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.loadData();
      })
  }

  loadData() {
    this.appService.fetchAllTasks(this.getLoadingParameters());
  }

  onFormSubmit() {
    this.ngxService.start();
    this.reportformsubmitted = true;
    
    if (this.reportForm.invalid)
      return;

    var campaigns = [] as Array<string>;
    campaigns = [createEmptyGuid()];

    let params = {
      companyId: this.companyId,
      reportFormat: this.reportFormat.value,
      campaigns: campaigns,
      aggregation: this.aggregation.value,
      platforms: this.formPlatforms.value,
      dateStart: this.startDate.value,
      dateStop: this.stopDate.value,
    };

    var paramsUrl = "" + this.router.createUrlTree([''], { queryParams: params });
    paramsUrl = paramsUrl.replace('/', '');

    this.rollupReportDataResponse = null;
    this.reportsService.startRollupReportProcess(paramsUrl)
      .subscribe(res => {
        this.rollupReportDataResponse = res.data;
        if (this.rollupReportDataResponse?.orderId) {
          this.dataLoadCompleted = false;
          this.active = 2;
          if (this.rollupReportDataResponse?.orderId) {
            this.interval = setInterval(() => {
              this.reportsService.getRollupReportProgress(this.rollupReportDataResponse?.orderId as string)
                .subscribe(res => {
                  if (res.data.status == 'Completed') {
                    clearInterval(this.interval);
                    this.dataLoadCompleted = true;
                    this.active = 3;
                    if (this.rollupReportDataResponse) {
                      this.reportsService.getRollupReport(this.rollupReportDataResponse?.orderId, this.iReportFormat)
                        .subscribe(responseUrl => {
                          document.querySelector('iframe')?.setAttribute("src", responseUrl);
                      });
                      this.loadCompleted = true;
                    }
                  }
              });
      
              this.reportsService.getRollupReportCampaignsProgress(this.rollupReportDataResponse?.orderId as string)
                .subscribe(res => {
                  for (var initial of res.data) {
                    var campaign = this.rollupReportDataResponse?.campaigns.find(f => f.id == initial.campaignId);
                    if (campaign) {
                      if (initial.status == "Faulted" || initial.status == "Completed") {
                        campaign.bundle = initial.status;
                        campaign.opportunityNotes = initial.message;
                      }
                      else if (initial.status == "Started" && !(campaign.bundle == "Faulted" || campaign.bundle == "Completed")) {
                        campaign.bundle = initial.status;
                        campaign.opportunityNotes = initial.message;
                      }
                    }
                  }
          
                  if (this.rollupReportDataResponse) {
                    this.rollupReportDataResponse.campaigns = this.rollupReportDataResponse.campaigns.sort((a, b) => a.bundle == 'Started' && b.bundle !== 'Started' ? -1 : 1); 
                  }
                });
      
            }, 1000);
          }
        }
    });
  }

  onReportPeriodChange(selectedPeriod: string) {
    let period = this.reportPeriods.find(f => f.key == selectedPeriod);

    this.key.setValue(selectedPeriod);
    this.startDate.setValue(period?.startDate);
    this.stopDate.setValue(period?.stopDate);

    this.active = 1;
  }

  handlePlatformsChange($event: any, val: string) {
    var target = $event.target;
    if (target.checked) {
      this.active = 1;
    }
  }

  onFrameLoadComplete() {
    if (this.rollupReportDataResponse) {
      this.loadCompleted = true;
    }
    this.ngxService.stopAll();
  }

  viewAllMMDash() {
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Campaigns);
    this.appService.getAllCampaigns$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.totalCampaignCount = response.totalCount;
        var totalActiveCampaigns = [...response.data].filter((x: any) => x.stage !== 'Inactive');
        this.totalActiveCampaignCount = totalActiveCampaigns.length;
        this.totalFacebookCampaignCount = totalActiveCampaigns.filter((x: any) => x.platform === 'Facebook').length;
        this.totalGoogleCampaignCount = totalActiveCampaigns.filter((x: any) => x.platform === 'Google').length;
        const counts = [...response.data].reduce((acc: { [key: string]: number }, item) => {
          let key = item['stageId']?.toString();
          if (key) {
            key = DASH_CAMPAIGN_STAGES.find(x => x.value === Number(key))?.title;
            if (key !== 'Inactive') {
              acc[key] = (acc[key] || 0) + 1;
            }
          }
          return acc;
        }, {});
    
        this.campValues = Object.entries(counts).map(([state, count]) => ({state, count: Number(count)}))
          .sort((a, b) =>  CALLING_DASH_CAMPAIGN_STAGES.indexOf(a.state) - (CALLING_DASH_CAMPAIGN_STAGES.indexOf(b.state)));
      });
    this.appService.fetchAllCampaigns({});


    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Clients);
    this.appService.getAllClients$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.totalClientCount = [...response.data].length;
      });
    this.appService.fetchAllClients({});

    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Leads);
    this.appService.getAllLeads$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.leads) {
          this.totalLeadCount = response.totalCount;
        }
      });
    this.appService.fetchAllLeads({pageNumber: 1, pageSize: 50});

    this.dataFeedService.getAssociatedCompanies()
      .subscribe(res => {
        this.companiesData = [...res.data];
        if ([...res.data].length > 1) {
          this.tooManyCompanies = true;
        } else {
          this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);
          let id = [...res.data][0]['id'];
          
          if (id) {
            this.companyId = id;
            if (!this.isEmptyCompanyGuid) {
              this.dataFeedService.getCompanyData(id)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((response: any) => {
                  this.companyData = response.data;
                });
            }
            this.dataFeedService.getCompanyAssociatedCampaigns(id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe((response: any) => {
                this.platforms = Array.from(new Set([...response.data].filter(f => f.campaignAdAccounts.length > 0).map(item => item.platform)));
              });
          }
          
          this.reportsService.getSupportedReportingPeriods()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: Array<any>) => {
              this.reportPeriods = response as Array<IReportingPeriod>;
              this.reportPeriods = this.reportPeriods.slice(1);
              this.onReportPeriodChange("Past Week");
            });
        }
      });
  }
}

export const DASH_CAMPAIGN_STAGES = [
  {
    title: "Tech Setup",
    value: 1
  },
  {
    title: "Campaign Set-Up",
    value: 2
  },
  {
    title: "Client Approval",
    value: 3
  },
  {
    title: "QC",
    value: 4
  },
  {
    title: "Waiting to Start",
    value: 5
  },
  {
    title: "Active",
    value: 6
  },
  {
    title: "Paused",
    value: 7
  },
  {
    title: "Inactive",
    value: 8
  },
  {
    title: "Cancelling",
    value: 9
  },
];

export const CALLING_DASH_CAMPAIGN_STAGES = [
  "Tech Setup",
  "Campaign Set-Up",
  "Client Approval",
  "QC",
  "Waiting to Start",
  "Active",
  "Cancelling",
  "Paused"
];
