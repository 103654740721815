import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ICampaign } from '../../../core/models/campaigns.model';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AppService } from '../../../store/app.service';
import { ReportsApiService } from '../reports.apiservice';

@Component({
  selector: 'app-reports-simple-campaigns',
  templateUrl: './reports-simple-campaigns.component.html',
  styleUrls: ['./reports-simple-campaigns.component.scss']
})
export class ReportsSimpleCampaignsComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', searchable: true },
  ]
  campaignsData: Array<any>
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private reportsService: ReportsApiService,) {
      this.campaignsData = new Array<any>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Campaign Reports');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);
    this.appService.getAllCampaigns$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: {good:boolean; data:Array<ICampaign>; totalCount:number}) => {
        this.campaignsData = response.data.map((campaign: any) => {
          if (campaign.stageId === 8) {
            campaign.statusColor = 'gray';
          }
          return campaign;
        });
        this.dataTotalCount = response.totalCount;
      });
    this.savedSearchId = this.route.snapshot.queryParams['savedsearchid']; 
  }
 
  private loadEvent!: LoadDataFromServer;
  dataTotalCount=0;
  savedSearchId!: string;
  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.appService.fetchAllCampaigns({
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    });
  }

  getRowLink(row: any) {
    return `/pages/reports/view-report/${row.id}`;
  }
}
