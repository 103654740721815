import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { pipe, ReplaySubject } from 'rxjs';
import { AppService } from '../../../store/app.service';
import { ReportsApiService } from '../reports.apiservice';
import { CampaignsApiService } from '../../campaigns/campaigns.apiservice';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { takeUntil } from 'rxjs/operators';
import { get } from 'underscore';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() Iid: string | null = null;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  reportMetrics: any = {};
  dailyLeads: { Date: string; Conversions: number }[] = [];
  platform: string = '';
  campaignName: string = '';
  reportForm: FormGroup;
  reportPeriods: Array<IReportingPeriod> = [];
  DateStart: string | null = null;
  DateStop: string | null = null;
  selectedPeriod: string | null = null;
  loadCompleted = false;
  validPlatform = true;

  defaultGoogleColumns = [
    { title: 'Cost', field: 'Cost', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
    { title: 'Impressions', field: 'Impressions', sortable: true, pipe: 'NumberPipe' },
    { title: 'Clicks', field: 'Clicks', sortable: true },
    { title: 'CTR', field: 'Ctr', sortable: true, pipe: 'PercentPipe' },
    { title: 'CPC', field: 'Cpc', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
    { title: 'Leads', field: 'Leads', sortable: true },
    { title: 'LSR', field: 'Lsr', sortable: true, pipe: 'PercentPipe' },
    { title: 'CPL', field: 'Cpl', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
  ];
  defaultMetaColumns = [
    { title: 'Cost', field: 'Cost', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
    { title: 'Impressions', field: 'Impressions', sortable: true, pipe: 'NumberPipe' },
    { title: 'CPM', field: 'Cpm', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
    { title: 'Clicks', field: 'Clicks', sortable: true },
    { title: 'CTR', field: 'Ctr', sortable: true, pipe: 'PercentPipe' },
    { title: 'Leads', field: 'Leads', sortable: true },
    { title: 'LSR', field: 'Lsr', sortable: true, pipe: 'PercentPipe' },
    { title: 'CPL', field: 'Cpl', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
  ];
  allCampaignsColumns: any[] = [];
  devicesColumns: any[] = [];
  adsColumns: any[] = [];
  googleKeywordsColumns: any[] = [];
  metaDemographicsColumns: any[] = [];
  metaAgeDemographicsColumns: any[] = [];
  metaGenderDemographicsColumns: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private reportsApiService: ReportsApiService,
    private campaignsApiService: CampaignsApiService,
    private fb: FormBuilder,
    private appService: AppService
  ) {
    this.reportForm = this.fb.group({
      key: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      stopDate: [null, [Validators.required]],
      showLeadsByDay: [true],
      showDevices: [false],
      showKeywords: [false],
      showAds: [false],
      showCaculator: [false],
      showDemographics: [false],
      Booked: [null],
      Show: [null],
      Sold: [null]
    });

    this.reportForm.get('Booked')?.valueChanges.subscribe(() => this.updateReportMetrics());
    this.reportForm.get('Show')?.valueChanges.subscribe(() => this.updateReportMetrics());
    this.reportForm.get('Sold')?.valueChanges.subscribe(() => this.updateReportMetrics());
  }

  ngOnInit(): void {
    this.loadReport();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Iid && !changes.Iid.isFirstChange()) {
      this.clearReportData();
      this.loadReport();
    }
  }

  clearReportData() {
    this.reportMetrics = {};
    this.dailyLeads = [];
    this.platform = '';
    this.campaignName = '';
    this.loadCompleted = false;
    this.validPlatform = true;
  }

  async loadReport() {
    if (!this.appService.getPageTitle$().getValue()) {
      this.appService.setPageTitle$('Campaign Report');
    }
    await this.getSupportedReportingPeriods();

    this.DateStart = this.reportPeriods.find(f => f.key == 'Past Week')?.startDate || '';
    this.DateStop = this.reportPeriods.find(f => f.key == 'Past Week')?.stopDate || '';
    this.selectedPeriod = 'Past Week';

    this.reportForm.patchValue({
      key: this.selectedPeriod,
      startDate: this.DateStart,
      stopDate: this.DateStop,
    });

    await this.getReportData();
  }

  getSupportedReportingPeriods(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.reportsApiService.getSupportedReportingPeriods()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: Array<IReportingPeriod>) => {
          this.reportPeriods = response;
          resolve();
        }, reject);
    });
  }

  async getReportData(): Promise<void> {
    let reachCampaignID = this.Iid || this.route.snapshot.paramMap.get('id');
    if (reachCampaignID) {
      await this.loadCampaignDetails(reachCampaignID);
    }

    if (this.platform === 'Google' || this.platform === 'Facebook') {
      let PerformanceMetrics = '7';

      if (reachCampaignID && this.platform && this.DateStart && this.DateStop) {
        this.reportsApiService.getRawCampaignsData(reachCampaignID, this.platform, this.DateStart, this.DateStop, PerformanceMetrics)
          .subscribe(
            (data) => {
              // console.log('Raw Campaigns Data:', data);
              if (!data.ErrorMessage && data.Platform) {
                this.reportMetrics.noData = '';
                this.reportMetrics.Platform = data.Platform.trim();
                this.reportMetrics.DateRange = data.GraphDateRange.trim();
                
                this.getOverviewMetrics(data);

                if (data.DaySplitsAdsPdfModels && data.DaySplitsAdsPdfModels.length > 1) {
                  // Multiple Campaigns
                  this.getIndividualCampaignsMetrics(data);
                }

                if (this.reportMetrics.Platform === 'Google') {
                  this.allCampaignsColumns = [
                    { title: 'Campaign', field: 'Name', sortable: true, searchable: true },
                    ...this.defaultGoogleColumns
                  ];
                  this.devicesColumns = [
                    { title: 'Device', field: 'Device', sortable: true, searchable: true },
                    ...this.defaultGoogleColumns
                  ];
                  this.googleKeywordsColumns = [
                    { title: 'Keyword', field: 'Keyword', sortable: true, searchable: true },
                    ...this.defaultGoogleColumns
                  ];
                  
                  // this.getGoogleAdsMetrics(data);
                  this.getGoogleKeywordsMetrics(data);
                  this.getGoogleDevicesMetrics(data);

                  this.reportForm.patchValue({
                    showKeywords: true,
                    showAds: false,
                    showDemographics: false
                  });
                } else if (this.reportMetrics.Platform === 'Meta') {
                  this.allCampaignsColumns = [
                    { title: 'Campaign', field: 'Name', sortable: true, searchable: true },
                    ...this.defaultMetaColumns
                  ];
                  this.devicesColumns = [
                    { title: 'Device', field: 'Device', sortable: true, searchable: true },
                    ...this.defaultMetaColumns
                  ];
                  this.adsColumns = [
                    { title: 'Thumbnail', type: 'image', width: '75px', field: 'ThumbnailUrl', sortable: false, searchable: false },
                    { title: 'Ad', type: 'html', field: 'AdText', sortable: true, searchable: true },
                    { title: 'Frequency', field: 'Frequency', sortable: true, pipe: 'NumberPipe', pipeArgs: [[2]] },
                    ...this.defaultMetaColumns
                  ];
                  this.metaDemographicsColumns = [
                    { title: 'Age', field: 'Age', sortable: true, searchable: true },
                    { title: 'Gender', field: 'Gender', sortable: true, searchable: true },
                    ...this.defaultMetaColumns
                  ];
                  this.metaAgeDemographicsColumns = [
                    { title: 'Age', field: 'Age', sortable: true, searchable: true },
                    ...this.defaultMetaColumns
                  ];
                  this.metaGenderDemographicsColumns = [
                    { title: 'Gender', field: 'Gender', sortable: true, searchable: true },
                    ...this.defaultMetaColumns
                  ];

                  this.getMetaAdsMetrics(data);
                  this.getMetaDemographicsMetrics(data);
                  this.getMetaDevicesMetrics(data);

                  this.reportForm.patchValue({
                    showKeywords: false,
                    showAds: true,
                    showDemographics: true
                  });
                }

                this.dailyLeads = this.getDailyLeads(data);
                this.loadCompleted = true;
              } else {
                // console.error('No data found for the selected campaign or date range.');
                this.reportMetrics = {};
                this.reportMetrics.noData = 'No data found for the selected campaign or date range.';
                this.loadCompleted = true;
              }
            },
            (error) => {
              console.error('Error fetching raw campaigns data:', error);
            }
          );
      }
      // console.log('Report Data:', this.reportMetrics);
    } else {
      console.error('Invalid Platform:', this.platform);
      this.loadCompleted = true;
      this.validPlatform = false;
    }
  } 

  toTitleCase(str: string): string {
    return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  loadCampaignDetails(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.campaignsApiService.getCampaignDetails(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.platform = response.data.platform;
          this.campaignName = response.data.name;
          resolve();
        }, reject);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getTotalMetrics(data: any, item: string): number {
    let total = 0;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const metric = data[key].Metrics;
        if (metric && metric[item]) {
          total += metric[item];
        }
      }
    }
    return total;
  }
  getDailyLeads(data: any): any {
    let leads: any = [];
    for (let i = 0; i < data.Data.length; i++) {
      leads[i] = {
        Conversions: data.Data[i] || 0,
        Date: data.Dates[i] || ''
      };
    }
    // Sort the leads array by the Date property
    leads.sort((a: any, b: any) => (a.Date && b.Date) ? a.Date.localeCompare(b.Date) : 0);
    // console.log('Daily Leads:', leads);
    return leads;
  }
  convertMicrosToDollars(micros: number): number {
    return micros / 1000000; // 1 micros = 1/1000000 dollar
  }

  getMaxConversions() {
    return Math.max(...this.dailyLeads.map(lead => lead.Conversions));
  }
  
  get recentLeads() {
    return this.dailyLeads.slice(-62);
  }

  onReportPeriodChange(selectedPeriod: string) {
    let period = this.reportPeriods.find(f => f.key == selectedPeriod);
    this.DateStart = period?.startDate as string;
    this.DateStop = period?.stopDate as string;

    this.reportForm.patchValue({
      key: selectedPeriod,
      startDate: this.DateStart,
      stopDate: this.DateStop,
    });
  }
  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.selectedReportPeriod == 'Custom'); }

  updateReport() {
    if (this.reportForm.invalid) {
      return;
    }
    this.loadCompleted = false;
    this.DateStart = this.reportForm.get('startDate')?.value;
    this.DateStop = this.reportForm.get('stopDate')?.value;
    this.selectedPeriod = this.reportForm.get('key')?.value;

    const formValues = {
      // Booked: this.reportForm.get('Booked')?.value,
      // Show: this.reportForm.get('Show')?.value,
      // Sold: this.reportForm.get('Sold')?.value,
      Booked: null,
      Show: null,
      Sold: null,
      showCaculator: this.reportForm.get('showCaculator')?.value
    };

    this.getReportData().then(() => {
      this.reportForm.patchValue(formValues);
      this.resetReportMetrics();
    });
  }

  formatAdBody(body: string): string {
    let shortened = body.length > 128 ? body.substring(0, 128) + '...' : body;
    let html = shortened.replace(/(?:\r\n|\r|\n)/g, '<br>');
    return html;
  }

  async convertImageUrlToBase64(imageUrl: string): Promise<string> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  async getImageUrl(imageUrl: string): Promise<string> {
    const base64ImageData = await this.convertImageUrlToBase64(imageUrl);
    // Remove the data URL prefix to get only the base64 string
    const base64String = base64ImageData.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
    return base64String;
  }

  getOverviewMetrics(data: any) {
    const Clicks = data.Clicks > 0 ? data.Clicks : 0;
    const Impressions = data.Impressions > 0 ? data.Impressions : 0;
    const Leads = data.Conversions > 0 ? data.Conversions : 0;
    const Cost = data.Cost > 0 ? data.Cost : 0;

    this.reportMetrics.Clicks = Clicks;
    this.reportMetrics.Impressions = Impressions;
    this.reportMetrics.Leads = Leads;
    this.reportMetrics.Cost = Cost;
    this.reportMetrics.Ctr = Impressions > 0 ? Clicks / Impressions : 0;
    this.reportMetrics.Cpc = Clicks > 0 ? Cost / Clicks : 0;
    this.reportMetrics.Cpm = Impressions > 0 ? (Cost / Impressions) * 1000 : 0;
    this.reportMetrics.Lsr = Clicks > 0 ? Leads / Clicks : 0;
    this.reportMetrics.Cpl = Leads > 0 ? Cost / Leads : 0;
  }

  getIndividualCampaignsMetrics(data: any) {
    this.reportMetrics.AllCampaigns = data.DaySplitsAdsPdfModels.map((model: any) => {
      const Clicks = model.Clicks > 0 ? model.Clicks : 0;
      const Impressions = model.Impressions > 0 ? model.Impressions : 0;
      const Leads = model.Conversions > 0 ? model.Conversions : 0;
      const Cost = model.Cost > 0 ? model.Cost : 0;
      return {
        Name: model.CampaignName.trim(),
        Clicks,
        Impressions,
        Leads,
        Cost,
        Ctr: (Impressions > 0 ? Clicks / Impressions : 0),
        Cpc: Clicks > 0 ? Cost / Clicks : 0,
        Cpm: Impressions > 0 ? (Cost / Impressions) * 1000 : 0,
        Lsr: (Clicks > 0 ? Leads / Clicks : 0),
        Cpl: Leads > 0 ? Cost / Leads : 0
      };
    });
  }

  getGoogleAdsMetrics(data: any) {
    this.reportMetrics.Ads = data.GoogleTopFives.map((ad: any) => {
      const Clicks = ad.clicks > 0 ? ad.clicks : 0;
      const Impressions = ad.impressions > 0 ? ad.impressions : 0;
      const Leads = ad.conversions > 0 ? ad.conversions : 0;
      const Cost = ad.cost > 0 ? ad.cost : 0;
      return {
        Clicks,
        Impressions,
        Leads,
        Cost,
        Ctr: Impressions > 0 ? Clicks / Impressions : 0,
        Cpc: Clicks > 0 ? Cost / Clicks : 0,
        Cpm: Impressions > 0 ? (Cost / Impressions) * 1000 : 0,
        Lsr: Clicks > 0 ? Leads / Clicks : 0,
        Cpl: Leads > 0 ? Cost / Leads : 0,
        Desc1: ad.desc1,
        Desc2: ad.desc2,
        HeadlinePart1: ad.headlinePart1,
        HeadlinePart2: ad.headlinePart2,
        HeadlinePart3: ad.headlinePart3
      };
    });
  }

  getGoogleKeywordsMetrics(data: any) {
    this.reportMetrics.Keywords = data.GoogleKeywords.map((keyword: any) => {
      const Clicks = keyword.clicks > 0 ? keyword.clicks : 0;
      const Impressions = keyword.impressions > 0 ? keyword.impressions : 0;
      const Leads = keyword.conversions > 0 ? keyword.conversions : 0;
      const Cost = keyword.cost > 0 ? keyword.cost : 0;
      return {
        Clicks,
        Impressions,
        Leads,
        Cost,
        Ctr: Impressions > 0 ? Clicks / Impressions : 0,
        Cpc: Clicks > 0 ? Cost / Clicks : 0,
        Cpm: Impressions > 0 ? (Cost / Impressions) * 1000 : 0,
        Lsr: Clicks > 0 ? Leads / Clicks : 0,
        Cpl: Leads > 0 ? Cost / Leads : 0,
        Keyword: keyword.keyword
      };
    }).reduce((acc: any, curr: any) => {
      const existingKeyword = acc.find((item: any) => item.Keyword === curr.Keyword);
      if (existingKeyword) {
        existingKeyword.Clicks += curr.Clicks;
        existingKeyword.Impressions += curr.Impressions;
        existingKeyword.Leads += curr.Leads;
        existingKeyword.Cost = existingKeyword.Cost + curr.Cost;
        existingKeyword.Ctr = existingKeyword.Impressions > 0 ? existingKeyword.Clicks / existingKeyword.Impressions : 0;
        existingKeyword.Cpc = existingKeyword.Clicks > 0 ? existingKeyword.Cost / existingKeyword.Clicks : 0;
        existingKeyword.Cpm = existingKeyword.Impressions > 0 ? (existingKeyword.Cost / existingKeyword.Impressions) * 1000 : 0;
        existingKeyword.Lsr = existingKeyword.Clicks > 0 ? existingKeyword.Leads / existingKeyword.Clicks : 0;
        existingKeyword.Cpl = existingKeyword.Leads > 0 ? existingKeyword.Cost / existingKeyword.Leads : 0;
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  }

  getGoogleDevicesMetrics(data: any) {
    this.reportMetrics.Devices = data.GoogleDevices.map((device: any) => {
      const Clicks = device.clicks > 0 ? device.clicks : 0;
      const Impressions = device.impressions > 0 ? device.impressions : 0;
      const Leads = device.conversions > 0 ? device.conversions : 0;
      const Cost = device.cost > 0 ? device.cost : 0;
      return {
        Clicks,
        Impressions,
        Leads,
        Cost,
        Ctr: Impressions > 0 ? Clicks / Impressions : 0,
        Cpc: Clicks > 0 ? Cost / Clicks : 0,
        Cpm: Impressions > 0 ? (Cost / Impressions) * 1000 : 0,
        Lsr: Clicks > 0 ? Leads / Clicks : 0,
        Cpl: Leads > 0 ? Cost / Leads : 0,
        Device: device.device
      };
    }).reduce((acc: any, curr: any) => {
      const existingDevice = acc.find((item: any) => item.Device === curr.Device);
      if (existingDevice) {
        existingDevice.Clicks += curr.Clicks;
        existingDevice.Impressions += curr.Impressions;
        existingDevice.Leads += curr.Leads;
        existingDevice.Cost = existingDevice.Cost + curr.Cost;
        existingDevice.Ctr = existingDevice.Impressions > 0 ? existingDevice.Clicks / existingDevice.Impressions : 0;
        existingDevice.Cpc = existingDevice.Clicks > 0 ? existingDevice.Cost / existingDevice.Clicks : 0;
        existingDevice.Cpm = existingDevice.Impressions > 0 ? (existingDevice.Cost / existingDevice.Impressions) * 1000 : 0;
        existingDevice.Lsr = existingDevice.Clicks > 0 ? existingDevice.Leads / existingDevice.Clicks : 0;
        existingDevice.Cpl = existingDevice.Leads > 0 ? existingDevice.Cost / existingDevice.Leads : 0;
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  }

  async getMetaAdsMetrics(data: any) {
    const ads = await Promise.all(data.FacebookTopFives.map(async (ad: any) => {
      const Clicks = ad.clicks > 0 ? ad.clicks : 0;
      const Frequency = Number(ad.frequency) > 0 ? Number(ad.frequency) : 0;
      const Impressions = ad.impressions > 0 ? ad.impressions : 0;
      const Leads = Number(ad.leads) > 0 ? Number(ad.leads) : 0;
      const Cost = Number(ad.spend) ? Number(ad.spend) : 0;
      const ThumbnailUrl = await this.getImageUrl(ad.FbTopFiveModel[0].thumbnail_url);
      const AdText = '<strong>' + ad.FbTopFiveModel[0].title + '</strong>\n\n' + ad.FbTopFiveModel[0].body;
      return {
        Clicks,
        Frequency,
        Impressions,
        Leads,
        Cost,
        Ctr: Impressions > 0 ? Clicks / Impressions : 0,
        Cpc: Clicks > 0 ? Cost / Clicks : 0,
        Cpm: Impressions > 0 ? (Cost / Impressions) * 1000 : 0,
        Lsr: Clicks > 0 ? Leads / Clicks : 0,
        Cpl: Leads > 0 ? Cost / Leads : 0,
        Body: ad.FbTopFiveModel[0].body,
        ThumbnailUrl,
        Title: ad.FbTopFiveModel[0].title,
        AdName: ad.FbTopFiveModel[0].ad_name,
        AdText: this.formatAdBody(AdText)
      };
    }));
    this.reportMetrics.Ads = ads;
  }

  getMetaDemographicsMetrics(data: any) {
    this.reportMetrics.Demographics = data.FacebookDemographics.map((demographic: any) => {
      const mergedActions: any = {};
    
      demographic.actionsList.forEach((actionsList: any) => {
        const key = `${actionsList.age}-${actionsList.gender}`;
        if (!mergedActions[key]) {
          mergedActions[key] = {
            Clicks: 0,
            Impressions: 0,
            Leads: 0,
            Cost: 0,
            Age: actionsList.age,
            Gender: this.toTitleCase(actionsList.gender)
          };
        }
    
        if (actionsList.actions) {
          actionsList.actions.forEach((action: any) => {
            const value = Number(action.Value);
            if (action.ActionType === 'link_click') {
              mergedActions[key].Clicks += value > 0 ? value : 0;
            } else if (action.ActionType === 'lead') {
              mergedActions[key].Leads += value > 0 ? value : 0;
            }
          });
        } else {
          mergedActions[key].Clicks = 0;
          mergedActions[key].Leads = 0;
        }
    
        mergedActions[key].Impressions += actionsList.impressions > 0 ? actionsList.impressions : 0;
        mergedActions[key].Cost += Number(actionsList.spend) > 0 ? Number(actionsList.spend) : 0;
      });
    
      const mergedArray = Object.values(mergedActions).map((item: any) => {
        return {
          Clicks: item.Clicks,
          Impressions: item.Impressions,
          Leads: item.Leads,
          Cost: item.Cost,
          Ctr: item.Impressions > 0 ? item.Clicks / item.Impressions : 0,
          Cpc: item.Clicks > 0 ? item.Cost / item.Clicks : 0,
          Cpm: item.Impressions > 0 ? (item.Cost / item.Impressions) * 1000 : 0,
          Lsr: item.Clicks > 0 ? item.Leads / item.Clicks : 0,
          Cpl: item.Leads > 0 ? item.Cost / item.Leads : 0,
          Age: item.Age,
          Gender: item.Gender
        };
      });
    
      return mergedArray;
    }).flat();
    
    this.reportMetrics.AgeDemographics = this.reportMetrics.Demographics.reduce((acc: any, curr: any) => {
      if (!acc[curr.Age]) {
        acc[curr.Age] = {
          Clicks: 0,
          Impressions: 0,
          Leads: 0,
          Cost: 0,
          Age: curr.Age
        };
      }
      acc[curr.Age].Clicks += curr.Clicks;
      acc[curr.Age].Impressions += curr.Impressions;
      acc[curr.Age].Leads += curr.Leads;
      acc[curr.Age].Cost += curr.Cost;
      return acc;
    }, {});
    
    this.reportMetrics.AgeDemographics = Object.values(this.reportMetrics.AgeDemographics).map((item: any) => {
      return {
        Clicks: item.Clicks,
        Impressions: item.Impressions,
        Leads: item.Leads,
        Cost: item.Cost,
        Ctr: item.Impressions > 0 ? item.Clicks / item.Impressions : 0,
        Cpc: item.Clicks > 0 ? item.Cost / item.Clicks : 0,
        Cpm: item.Impressions > 0 ? (item.Cost / item.Impressions) * 1000 : 0,
        Lsr: item.Clicks > 0 ? item.Leads / item.Clicks : 0,
        Cpl: item.Leads > 0 ? item.Cost / item.Leads : 0,
        Age: item.Age
      };
    });
    
    this.reportMetrics.GenderDemographics = this.reportMetrics.Demographics.reduce((acc: any, curr: any) => {
      if (!acc[curr.Gender]) {
        acc[curr.Gender] = {
          Clicks: 0,
          Impressions: 0,
          Leads: 0,
          Cost: 0,
          Gender: curr.Gender
        };
      }
      acc[curr.Gender].Clicks += curr.Clicks;
      acc[curr.Gender].Impressions += curr.Impressions;
      acc[curr.Gender].Leads += curr.Leads;
      acc[curr.Gender].Cost += curr.Cost;
      return acc;
    }, {});
    
    this.reportMetrics.GenderDemographics = Object.values(this.reportMetrics.GenderDemographics).map((item: any) => {
      return {
        Clicks: item.Clicks,
        Impressions: item.Impressions,
        Leads: item.Leads,
        Cost: item.Cost,
        Ctr: item.Impressions > 0 ? item.Clicks / item.Impressions : 0,
        Cpc: item.Clicks > 0 ? item.Cost / item.Clicks : 0,
        Cpm: item.Impressions > 0 ? (item.Cost / item.Impressions) * 1000 : 0,
        Lsr: item.Clicks > 0 ? item.Leads / item.Clicks : 0,
        Cpl: item.Leads > 0 ? item.Cost / item.Leads : 0,
        Gender: item.Gender
      };
    });

    this.reportMetrics.AgeGenderDemographics = this.reportMetrics.Demographics.reduce((acc: any, curr: any) => {
      const key = `${curr.Age}-${curr.Gender}`;
      if (!acc[key]) {
        acc[key] = {
          Clicks: 0,
          Impressions: 0,
          Leads: 0,
          Cost: 0,
          Age: curr.Age,
          Gender: curr.Gender
        };
      }
      acc[key].Clicks += curr.Clicks;
      acc[key].Impressions += curr.Impressions;
      acc[key].Leads += curr.Leads;
      acc[key].Cost += curr.Cost;
      return acc;
    }, {});

    this.reportMetrics.AgeGenderDemographics = Object.values(this.reportMetrics.AgeGenderDemographics).map((item: any) => {
      return {
        Clicks: item.Clicks,
        Impressions: item.Impressions,
        Leads: item.Leads,
        Cost: item.Cost,
        Ctr: item.Impressions > 0 ? item.Clicks / item.Impressions : 0,
        Cpc: item.Clicks > 0 ? item.Cost / item.Clicks : 0,
        Cpm: item.Impressions > 0 ? (item.Cost / item.Impressions) * 1000 : 0,
        Lsr: item.Clicks > 0 ? item.Leads / item.Clicks : 0,
        Cpl: item.Leads > 0 ? item.Cost / item.Leads : 0,
        Age: item.Age,
        Gender: item.Gender
      };
    });
  }

  getMetaDevicesMetrics(data: any) {
    this.reportMetrics.Devices = data.FacebookDevices.map((device: any) => {
      const mergedActions: any = {};
    
      device.actionsList.forEach((actionsList: any) => {
        const key = actionsList.device_platform;
        if (!mergedActions[key]) {
          mergedActions[key] = {
            Clicks: 0,
            Impressions: 0,
            Leads: 0,
            Cost: 0,
            Device: this.toTitleCase(actionsList.device_platform)
          };
        }
    
        if (actionsList.actions) {
          actionsList.actions.forEach((action: any) => {
            const value = Number(action.Value);
            if (action.ActionType === 'link_click') {
              mergedActions[key].Clicks += value > 0 ? value : 0;
            } else if (action.ActionType === 'lead') {
              mergedActions[key].Leads += value > 0 ? value : 0;
            }
          });
        } else {
          mergedActions[key].Clicks = 0;
          mergedActions[key].Leads = 0;
        }

        mergedActions[key].Impressions += actionsList.impressions > 0 ? actionsList.impressions : 0;
        mergedActions[key].Cost += Number(actionsList.spend) > 0 ? Number(actionsList.spend) : 0;
      });
    
      return Object.values(mergedActions);
    }).flat().reduce((acc: any, curr: any) => {
      const existingDevice = acc.find((item: any) => item.Device === curr.Device);
      if (existingDevice) {
        existingDevice.Clicks += curr.Clicks;
        existingDevice.Impressions += curr.Impressions;
        existingDevice.Leads += curr.Leads;
        existingDevice.Cost = existingDevice.Cost + curr.Cost;
        existingDevice.Ctr = existingDevice.Impressions > 0 ? existingDevice.Clicks / existingDevice.Impressions : 0;
        existingDevice.Cpc = existingDevice.Clicks > 0 ? existingDevice.Cost / existingDevice.Clicks : 0;
        existingDevice.Cpm = existingDevice.Impressions > 0 ? (existingDevice.Cost / existingDevice.Impressions) * 1000 : 0;
        existingDevice.Lsr = existingDevice.Clicks > 0 ? existingDevice.Leads / existingDevice.Clicks : 0;
        existingDevice.Cpl = existingDevice.Leads > 0 ? existingDevice.Cost / existingDevice.Leads : 0;
      } else {
        acc.push({
          ...curr,
          Ctr: curr.Impressions > 0 ? curr.Clicks / curr.Impressions : 0,
          Cpc: curr.Clicks > 0 ? curr.Cost / curr.Clicks : 0,
          Cpm: curr.Impressions > 0 ? (curr.Cost / curr.Impressions) * 1000 : 0,
          Lsr: curr.Clicks > 0 ? curr.Leads / curr.Clicks : 0,
          Cpl: curr.Leads > 0 ? curr.Cost / curr.Leads : 0
        });
      }
      return acc;
    }, []);
  }

  updateReportMetrics() {
    const Booked = this.reportForm.get('Booked')?.value || 0;
    const Show = this.reportForm.get('Show')?.value || 0;
    const Sold = this.reportForm.get('Sold')?.value || 0;

    const Leads = this.reportMetrics.Leads > 0 ? this.reportMetrics.Leads : 0;
    const Cost = this.reportMetrics.Cost > 0 ? this.reportMetrics.Cost : 0;
  
    if (Booked) {
      this.reportMetrics.Booked = Booked;
      this.reportMetrics.BookingPercent = Booked / Leads;
      this.reportMetrics.CostPerBooking = Cost / Booked;
    } else {
      this.reportMetrics.Booked = null;
      this.reportMetrics.BookingPercent = null;
      this.reportMetrics.CostPerBooking = null;
    }

    if (Show) {
      this.reportMetrics.Show = Show;
      this.reportMetrics.LeadToShowPercent = Show / Leads;
      this.reportMetrics.CostPerShow = Cost / Show;
    } else {
      this.reportMetrics.Show = null;
      this.reportMetrics.LeadToShowPercent = null;
      this.reportMetrics.CostPerShow = null;
    }

    if (Booked && Show) {
      this.reportMetrics.BookToShowPercent = Show / Booked;
    } else {
      this.reportMetrics.BookToShowPercent = null;
    }

    if (Sold) {
      this.reportMetrics.Sold = Sold;
      this.reportMetrics.LeadToSoldPercent = Sold / Leads;
      this.reportMetrics.CostPerAcquisition = Cost / Sold;
    } else {
      this.reportMetrics.Sold = null;
      this.reportMetrics.LeadToSoldPercent = null;
      this.reportMetrics.CostPerAcquisition = null;
    }

    if (Booked && Sold) {
      this.reportMetrics.BookToSoldPercent = Sold / Booked;
    } else {
      this.reportMetrics.BookToSoldPercent = null;
    }

    if (Show && Sold) {
      this.reportMetrics.ShowToSoldPercent = Sold / Show;
    } else {
      this.reportMetrics.ShowToSoldPercent = null;
    }
  }

  resetReportMetrics() {
    this.reportMetrics.Booked = 0;
    this.reportMetrics.BookingPercent = 0;
    this.reportMetrics.CostPerBooking = 0;
    this.reportMetrics.Show = 0;
    this.reportMetrics.LeadToShowPercent = 0;
    this.reportMetrics.CostPerShow = 0;
    this.reportMetrics.BookToShowPercent = 0;
    this.reportMetrics.Sold = 0;
    this.reportMetrics.LeadToSoldPercent = 0;
    this.reportMetrics.CostPerAcquisition = 0;
    this.reportMetrics.BookToSoldPercent = 0;
    this.reportMetrics.ShowToSoldPercent = 0;
  }

}
