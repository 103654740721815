import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { IPermission } from '../../../core/models/auth.models';
import { ICompany } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { ReportsApiService } from '../reports.apiservice';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-reports-brand-report',
  templateUrl: './reports-brand-report.component.html',
  styleUrls: ['./reports-brand-report.component.scss']
})
export class ReportsBrandDashboardComponent implements OnInit, OnDestroy {
  doWhat: IPermission = {} as IPermission;
  companiesData: Array<ICompany> = [];
  selectedCompanyId: string = '';
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isAdmin: boolean = false;
  roleOptions: any = {};
  isExternal: boolean = false;
  isCompanyOwner: boolean = false;
  loading: boolean = false;
  campaignsData: Array<any>

  reportForm: FormGroup;
  reportPeriods: Array<IReportingPeriod> = [];
  DateStart: string | null = null;
  DateStop: string | null = null;
  selectedPeriod: string | null = null;
  companyMetaData: { [key: string]: any }[] = [];
  companyMetaTotals: { [key: string]: string }[] = [];
  companyMetaAverages: { [key: string]: string }[] = [];
  companyGoogleData: { [key: string]: string; }[] = [];
  companyGoogleTotals: { [key: string]: string }[] = [];
  companyGoogleAverages: { [key: string]: string }[] = [];
  activeTab: string = 'meta';

  defaultColumns = [
    { title: 'Location', field: 'location', sortable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'reach_campaign_id', openInNewTab: true, searchable: true },
    { title: 'Owner', field: 'client_name', sortable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'reach_client_id', openInNewTab: true, searchable: true },
    { title: 'Type', field: 'campaign_type', sortable: true, searchable: true },
    { title: 'Stage', field: 'campaign_stage', sortable: true, searchable: true },
    { title: 'Monthly Budget*', field: 'budget', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.0-0'] },
    { title: 'Ad Spend', field: 'cost', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'], hoverValue: 'cost_prev' },
    { title: 'Spend Change', field: 'cost_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'cost_updown' },
  ];

  defaultAfterColumns = [
    { title: 'CTR', field: 'ctr', sortable: true, pipe: 'PercentPipe', hoverValue: 'ctr_prev' },
    { title: 'CTR Change', field: 'ctr_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'ctr_updown' },
    { title: 'Leads', field: 'leads', sortable: true, pipe: 'NumberPipe', hoverValue: 'leads_prev' },
    { title: 'Leads Change', field: 'leads_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'leads_updown' },
    { title: 'LSR', field: 'lsr', sortable: true, pipe: 'PercentPipe', hoverValue: 'lsr_prev' },
    { title: 'LSR Change', field: 'lsr_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'lsr_updown' },
    { title: 'CPL', field: 'cpl', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'], statusClass: 'redalert' },
    { title: 'Previous CPL', field: 'cpl_prev', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'] },
    { title: 'CPL Change', field: 'cpl_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'cpl_updown' },
    { title: 'DMS', field: 'dms', sortable: true, searchable: true },
  ]

  defaultGoogleColumns = [
    ...this.defaultColumns,
    { title: 'Impr.', field: 'impressions', sortable: true, pipe: 'NumberPipe', hoverValue: 'impressions_prev' },
    { title: 'Impr. Change', field: 'impressions_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'impressions_updown' },
    { title: 'CPC', field: 'cpc', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'], hoverValue: 'cpc_prev' },
    { title: 'CPC Change', field: 'cpc_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'cpc_updown' },
    ...this.defaultAfterColumns
  ];
  defaultMetaColumns = [
    ...this.defaultColumns,
    { title: 'CPM', field: 'cpm', sortable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.2-2'], hoverValue: 'cpm_prev' },
    { title: 'CPM Change', field: 'cpm_compare', sortable: true, pipe: 'PercentPipe', statusClass: 'cpm_updown' },
    ...this.defaultAfterColumns
  ];

  defaultPageSize = 10;

  constructor(
    private router: Router,
    private appService: AppService, 
    private reportsApiService: ReportsApiService,
    private fb: FormBuilder,
    private dataFeedService: DataFeedService
  ) {
    this.reportForm = this.fb.group({
      key: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      stopDate: [null, [Validators.required]],
      companySelector: [null, [Validators.required]]
    });
    this.campaignsData = new Array<any>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Brand Dashboard');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type === ROLES_TYPE.External;
        this.isCompanyOwner = u.roles.some(r => r.code === 5);
        const r = getRolesForPage(u.roles, PAGE_NAMES.Reports);
        this.isAdmin = r.isAdmin;
        this.roleOptions = r.options ? JSON.parse(r.options ?? '{}') : {};
      });

    this.getAssociatedCompanies(); 
    this.getSupportedReportingPeriods().then(() => {
      this.setPreviousMonthPeriod();
    });
    // console.log('this.campaignsData', this.campaignsData);
  }

  setPreviousMonthPeriod() {
    const previousMonthPeriod = this.reportPeriods.find(period => period.key === 'Previous Month');
    if (previousMonthPeriod) {
      this.DateStart = previousMonthPeriod.startDate;
      this.DateStop = previousMonthPeriod.stopDate;
      this.selectedPeriod = previousMonthPeriod.key ?? null;

      this.reportForm.patchValue({
        key: this.selectedPeriod,
        startDate: this.DateStart,
        stopDate: this.DateStop,
      });
    }
  }

  getSupportedReportingPeriods(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.reportsApiService.getSupportedReportingPeriods()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: Array<IReportingPeriod>) => {
          this.reportPeriods = response;
          resolve();
        }, reject);
    });
  }
  onReportPeriodChange(selectedPeriod: string) {
    let period = this.reportPeriods.find(f => f.key == selectedPeriod);
    this.DateStart = period?.startDate as string;
    this.DateStop = period?.stopDate as string;

    this.reportForm.patchValue({
      key: selectedPeriod,
      startDate: this.DateStart,
      stopDate: this.DateStop,
    });
  }
  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.selectedReportPeriod == 'Custom'); }

  getAssociatedCompanies() {
    if (this.isExternal) {
      this.dataFeedService.getAssociatedCompanies()
        .subscribe(res => {
          // Remove duplicate companies based on id
          const uniqueCompanies = res.data.filter((company: ICompany, index: number, self: ICompany[]) =>
            index === self.findIndex((c: ICompany) => c.id === company.id)
          );

          // Sort companies alphabetically by name
          this.companiesData = [...uniqueCompanies].sort((a, b) => a.name.localeCompare(b.name));
    
          // If only one company
          if (this.companiesData.length === 1) {
            if (this.companiesData[0].id) {
              this.selectedCompanyId = this.companiesData[0].id;
              this.reportForm.patchValue({ companySelector: this.selectedCompanyId });
            }
          }
        });

        if (!this.isCompanyOwner) {
          // Marketing Manager only
          this.appService.getAllCampaigns$()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: {good:boolean; data:Array<any>; totalCount:number}) => {
              this.campaignsData = response.data;
            });
          this.appService.fetchAllCampaigns({});
        }
    } else {
      this.appService.getAllCompanies$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: Array<ICompany>) => {
        // Remove duplicate companies based on id
        const uniqueCompanies = response.filter((company: ICompany, index: number, self: ICompany[]) =>
          index === self.findIndex((c: ICompany) => c.id === company.id)
        );

        // Sort companies alphabetically by name
        this.companiesData = [...uniqueCompanies].sort((a, b) => a.name.localeCompare(b.name));
      });
      this.appService.fetchAllCompanies(null);
    }
  }

  updateReport() {
    if (this.reportForm.invalid) {
      this.reportForm.markAllAsTouched(); // Mark all controls as touched to trigger validation
      return;
    }
    this.DateStart = this.reportForm.get('startDate')?.value;
    this.DateStop = this.reportForm.get('stopDate')?.value;
    this.selectedPeriod = this.reportForm.get('key')?.value;

    // Update selectedCompanyId from the form control value
    this.selectedCompanyId = this.reportForm.get('companySelector')?.value;

    this.loading = true;
    this.updateData(this.selectedCompanyId).finally(() => {
      this.loading = false;
    });
  }

  async updateData(companyId: string) {
    const company = this.companiesData.find(c => c.id === companyId);
    if (company && this.DateStart && this.DateStop) {
      const currentStartDate = new Date(this.DateStart);
      const currentEndDate = new Date(this.DateStop);

      try {
        const meta = await this.fetchDataJson('meta', company.name, currentStartDate.toISOString().split('T')[0], currentEndDate.toISOString().split('T')[0]);
        this.companyMetaData = [];
        this.companyMetaTotals = [];
        this.companyMetaAverages = [];

        if (this.isExternal && !this.isCompanyOwner) {
          // Marketing Manager
          for (const key in meta) {
            if (key === 'totals') {
              continue;
            } else if (key === 'averages') {
              continue;
            } else {
              // Only push if reach_campaign_id matches one of the ids in this.campaignsData
              if (this.campaignsData.some(campaign => campaign.id === meta[key].reach_campaign_id)) {
                this.companyMetaData.push(meta[key]);
              }
            }
          }
        } else {
          for (const key in meta) {
            if (key === 'totals') {
              this.companyMetaTotals.push(meta[key]);
            } else if (key === 'averages') {
              this.companyMetaAverages.push(meta[key]);
            } else {
              this.companyMetaData.push(meta[key]);
            }
          }
        }
        // console.log('this.companyMetaData', this.companyMetaData);
        // console.log('this.companyMetaTotals', this.companyMetaTotals);
        // console.log('this.companyMetaAverages', this.companyMetaAverages);

        const google = await this.fetchDataJson('google', company.name, currentStartDate.toISOString().split('T')[0], currentEndDate.toISOString().split('T')[0]);
        this.companyGoogleData = [];
        this.companyGoogleTotals = [];
        this.companyGoogleAverages = [];

        if (this.isExternal && !this.isCompanyOwner) {
          // Marketing Manager
          for (const key in google) {
            if (key === 'totals') {
              continue;
            } else if (key === 'averages') {
              continue;
            } else {
              // Only push if reach_campaign_id matches one of the ids in this.campaignsData
              if (this.campaignsData.some(campaign => campaign.id === google[key].reach_campaign_id)) {
                this.companyGoogleData.push(google[key]);
              }
            }
          }
        } else {
          for (const key in google) {
            if (key === 'totals') {
              this.companyGoogleTotals.push(google[key]);
            } else if (key === 'averages') {
              this.companyGoogleAverages.push(google[key]);
            } else {
              this.companyGoogleData.push(google[key]);
            }
          }
        }
        // console.log('this.companyGoogleData', this.companyGoogleData);
        // console.log('this.companyGoogleTotals', this.companyGoogleTotals);
        // console.log('this.companyGoogleAverages', this.companyGoogleAverages);
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  }

  fetchDataJson(platform: string, companyName: string, startDate: string, endDate: string): Promise<{ [key: string]: string }[]> {
    const url = `https://dms.reach.marketing/dmm/cmo-reach.php?company=${companyName}&start-date=${startDate}&end-date=${endDate}&platform=${platform}`;
    
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        // const arrayData = Object.keys(data).map(key => data[key]);
        // console.log('Array data:', arrayData);
        return data;
      })
      .catch(error => {
        console.error('Error fetching JSON data:', error);
        return [];
      });
  }

  getActiveCompanyName(): string {
    const company = this.companiesData.find(c => c.id === this.selectedCompanyId);
    return company ? company.name : '';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}