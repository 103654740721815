<div class="row">
    <div class="col-12">
        <div class="card card-custom">
            <form [formGroup]="dtForm" *ngIf="iShowHeader">
                <div class="card-header">
                    <div class="d-flex flex-md-row flex-lg-row flex-sm-row justify-content-between align-items-center">
                        <div class="d-flex flex-row align-items-center">
                            <div *ngIf="iShowSearch" id="tickets-table_filter" class="dataTables_filter text-md-end">
                                <div *ngIf="iShowSearch" class="search-container">
                                    <i-feather name="search"></i-feather>
                                    <input id="txtSearchQuery" type="text" formControlName="searchQuery" placeholder="Search" class="form-control form-control-sm ms-2"
                                        aria-controls="tickets-table" (keydown.enter)="doServerSearch($event)">
                                </div>
                                <div class="d-inline-block hide-from-980">
                                    <button *ngIf="!iHideHeaderActions && (isAdmin || iShowExport)" type="button" class="blue-button"
                                    (click)="exportToCsv()">EXPORT</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="iReportTitle" class="d-flex flex-row align-items-center">
                            <div  id="tickets-table_filter" class="dataTables_filter text-md-center">
                                <div class="d-inline-block">
                                    <h3 style="margin-bottom: 0;">{{ iReportTitle | titlecase }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row align-items-center hide-from-small" style="justify-content: flex-end;">
                            <div class="d-flex justify-content-between align-items-center">
                                <select name="tickets-table_length" aria-controls="tickets-table" formControlName="pageSize" (change)="onPageSizeChange($event)" class="form-control form-select select-custom">
                                    <option [value]="10">10 Per Page</option>
                                    <option [value]="25">25 Per Page</option>
                                    <option [value]="50">50 Per Page</option>
                                    <option [value]="100">100 Per Page</option>
                                    <option [value]="250">250 Per Page</option>
                                    <option [value]="500">500 Per Page</option>
                                </select>
                                <div class="text-md-right float-md-end pagination-rounded">
                                    <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                                        [collectionSize]="collectionSize" [page]="pageNumber" [boundaryLinks]="true"
                                        (pageChange)="onPageChange($event)" [pageSize]="f.pageSize.value">
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </form>
            <div class="card-body">
                <table cellspacing="0" cellpadding="0" id="datatable" class="table dt-responsive wrap w-100">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let col of iColumns; let i=index">
                                <th *ngIf="col.visible === undefined || col.visible" dtSortable
                                    [attr.sortable]="(col.sortable  && col.sortable)? col.field : null"
                                    [sortable]="(col.sortable  && col.sortable)? col.field : null"
                                    (sort)="onSort($event, col)">
                                    {{col.title}}
                                    <ng-container *ngIf="col.title === '' && col.field === 'media'">
                                        <i class="far fa-image fa-2x"></i>
                                    </ng-container>
                                    <ng-container *ngIf="col.title === '' && col.field === 'html'">
                                        <i class="fas fa-code fa-2x"></i>
                                    </ng-container>
                                </th>
                            </ng-container>
                            <th *ngIf="(iShowActions || iShowViewAction)">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="viewData.length==0">
                            <td style="text-align: center;"
                                [attr.colspan]="(iColumns.length + 1) + ( iShowActions ? 1 :0)">
                                No data found
                            </td>
                        </tr>
                        <tr [style.color]="row.isDeleted ? 'red' : null" [ngClass]="row.statusColor ? row.statusColor : ''" *ngFor="let row of viewData; let i=index">
                            <td [ngClass]="extractFieldValue(row, col.statusClass,'', []) ? extractFieldValue(row, col.statusClass,'', []) : ''" *ngFor="let col of iColumns; let j=index" style="padding: 0px;height: 30px;">
                                <label class="task-label">{{col.title}}: </label>
                                <span *ngIf="(col.visible === undefined || col.visible )"
                                    (click)="oRowClick.emit(row)" [routerLink]="getRowLink(row).routerLink"
                                    [queryParams]="getRowLink(row).queryParams" role="link"
                                    >
                                    <div *ngIf="col.anchorLink && col.anchorLink; else html">
                                        <a style="color: #2563eb;" [href]="getAnchorLink(col.anchorType, row[col.anchorParamsField])" [attr.target]="col.openInNewTab ? '_blank' : '_self'">
                                            {{row[col.field]}}
                                        </a>
                                    </div>
                                    <ng-template #html>
                                        <div *ngIf="col.type === 'html'; else image"
                                            [innerHTML]="SafeHtml(row[col.field])">
                                        </div>
                                    </ng-template>
                                    <ng-template #image>
                                        <div *ngIf="col.type === 'image'; else normal">
                                            <img [src]="SafeUrl(extractFieldValue(row, col.field,col.pipe, col?.pipeArgs))" 
                                                 [style.width]="col.width ? col.width : 'auto'" 
                                                 [style.height]="col.width ? 'auto' : null" />
                                        </div>
                                    </ng-template>
                                    <ng-template #normal>
                                        <ngb-highlight
                                            (mouseover)="col.hoverValue ? showTooltip($event, extractFieldValue(row, col.hoverValue, col.pipe, col?.pipeArgs)) : null"
                                            (mouseout)="hideTooltip()"
                                            [result]="extractFieldValue(row, col.field, col.pipe, col?.pipeArgs)">
                                        </ngb-highlight>
                                    </ng-template>
                                </span>
                            </td>
                            <td *ngIf="( iShowActions || iShowViewAction )">
                                <div class="actions-container">
                                    <a *ngIf="iShowViewAction && (isAdmin || iCanRead)" class="a-btn"
                                        (click)="viewRow(row);$event.stopPropagation();"
                                        title="View">
                                        <i-feather name="eye"></i-feather>
                                    </a>
                                    <a *ngIf="iShowExternalAction && (isAdmin || iCanRead)" class="a-btn"
                                        (click)="showExternal(row);$event.stopPropagation();"
                                        title="Opens on new page">
                                        <i-feather name="external-link"></i-feather>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="totalData.length > 0">
                        <tr>
                            <td [ngClass]="extractFieldValue(totalData[0], col.statusClass,'', []) ? extractFieldValue(totalData[0], col.statusClass,'', []) : ''" *ngFor="let col of iColumns">
                                <span *ngIf="totalData[0][col.field] !== undefined"
                                (mouseover)="col.hoverValue ? showTooltip($event, extractFieldValue(totalData[0], col.hoverValue, col.pipe, col?.pipeArgs)) : null"
                                (mouseout)="hideTooltip()">
                                    {{ extractFieldValue(totalData[0], col.field, col.pipe, col.pipeArgs) }}
                                </span>
                                <span *ngIf="totalData[0][col.field] === undefined"></span>
                            </td>
                            <td *ngIf="(iShowActions || iShowViewAction)"></td>
                        </tr>
                    </tfoot>
                    <tfoot *ngIf="averageData.length > 0">
                        <tr>
                            <td [ngClass]="extractFieldValue(averageData[0], col.statusClass,'', []) ? extractFieldValue(averageData[0], col.statusClass,'', []) : ''" *ngFor="let col of iColumns">
                                <span *ngIf="averageData[0][col.field] !== undefined"
                                (mouseover)="col.hoverValue ? showTooltip($event, extractFieldValue(averageData[0], col.hoverValue, col.pipe, col?.pipeArgs)) : null"
                                (mouseout)="hideTooltip()">
                                    {{ extractFieldValue(averageData[0], col.field, col.pipe, col.pipeArgs) }}
                                </span>
                                <span *ngIf="averageData[0][col.field] === undefined"></span>
                            </td>
                            <td *ngIf="(iShowActions || iShowViewAction)"></td>
                        </tr>
                    </tfoot>
                </table>
                <div id="{{iReportTitle}}hoverValue" class="hoverValue" [innerHTML]="tooltipText"></div>
                <form [formGroup]="dtForm" *ngIf="iShowFooter">
                    <div class="d-flex flex-row align-items-center" style="justify-content: flex-end;">
                        <div class="d-flex justify-content-between align-items-center">
                            <select name="tickets-table_length" aria-controls="tickets-table" formControlName="pageSize" (change)="onPageSizeChange($event)" class="form-control form-select select-custom">
                                <option [value]="10">10 Per Page</option>
                                <option [value]="25">25 Per Page</option>
                                <option [value]="50">50 Per Page</option>
                                <option [value]="100">100 Per Page</option>
                                <option [value]="250">250 Per Page</option>
                                <option [value]="500">500 Per Page</option>
                            </select>
                            <div class="text-md-right float-md-end pagination-rounded">
                                <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                                    [collectionSize]="collectionSize" [page]="pageNumber" [boundaryLinks]="true"
                                    (pageChange)="onPageChange($event)" [pageSize]="f.pageSize.value">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div> <!-- end col -->
    </div> <!-- end row -->
</div>