<ul *ngIf="!externalUser" class="tab-titles">
    <li *ngFor="let tab of tabs; let t = index;" (click)="selectTab(tab)" [ngStyle]="{'margin-left' : (t !== 0) ? '-15px' : '0px', 'z-index' : (activeTab === tab) ? '5' : '0'}" [ngClass]="(activeTab === tab) ? 'activeLI' : 'inactiveLI'"><h5>{{ tab }}</h5></li>
</ul>
<div class="todo-card" [ngStyle]="{'display': ((activeTab === 'Tasks') && !externalUser) ? 'block' : 'none'}">
    <div fxLayout="row" fxLayoutAlign="space-between" style="padding-left: 16px;">
        <h2>TO-DO</h2>
    </div>
    <div class="card-body">
        <app-tasks-datatable [iSortColumn]="'title'" [iShowActions]="userRoles.includes(ROLES.Admin.Code)"
            (oRowClick)="editRow($event)" [iUserRoles]="userRoles" (oCBClick)="onCheckBoxChange($event)"
            [iHideHeaderActions]="true" [iColumns]="columns" (oDeleteRows)="deleteRows($event)"
            (oEditRow)="editRow($event)" [iData$]="todoTasksData" [iIsServerLoading]="true" [iTotalCount]="dataTotalCount" 
            (oLoadDataFromServer)="onLoadingData($event)"></app-tasks-datatable>
    </div>
</div>
<div *ngIf="((activeTab === 'Dashboard') && !externalUser) || (activeTab === 'Sales Pipeline') || (activeTab === 'DMS Dash')" class="todo-card">
    <iframe allow="clipboard-read; clipboard-write" [style.height]="getHeight" style="width: 100%;"[src]="iframeSrc" scrolling="yes"></iframe>
</div>
<div style="margin-top: 20px;" *ngIf="(activeTab === 'Widgets')" class="d-flex flex-wrap" [sortablejs]="widgets" [sortablejsOptions]="sortableOptions">
    <ng-container *ngFor="let widget of widgets;">
        <app-widget [widget]="widget" [id]='widget.id'></app-widget>
    </ng-container>
</div>
<div *ngIf="(activeTab === 'Manager Dash') || externalUser" class="mm-dash-row">
    <div class="mm-dash-cell">
        <h5 class="cell-title">Summary</h5>
        <div class="under-title-inner-cell">
            <div class="inner-cell-block">
                <h5>Total Campaigns:</h5>
                <div class="campaign-totals-row">
                    <h3>{{ totalActiveCampaignCount }}</h3>
                </div>
            </div>
            <div class="inner-cell-block">
                <h5>Total Meta Campaigns:</h5>
                <div class="campaign-totals-row">
                    <h3>{{ totalFacebookCampaignCount }}</h3>
                </div>
            </div>
            <div class="inner-cell-block">
                <h5>Total Google Campaigns:</h5>
                <div class="campaign-totals-row">
                    <h3>{{ totalGoogleCampaignCount }}</h3>
                </div>
            </div>
            <div class="inner-cell-block">
                <h5>Total # Of Clients:</h5>
                <div class="campaign-totals-row">
                    <h3>{{ this.totalClientCount }}</h3>
                </div>
            </div>
        </div>
        <div class="inner-cell-block">
            <p class="disclaimer-text">Total campaigns include all active campaigns as well as those currently in the setup phases.</p>
        </div>
    </div>
    <div *ngIf="campValues.length > 0" class="mm-dash-cell">
        <h5 class="cell-title">Stages</h5>
        <div class="under-title-inner-cell">
            <div *ngFor="let item of campValues;" class="inner-cell-block">
                <h5>{{ item.state }} Campaigns: </h5>
                <div class="campaign-totals-row">
                    <h3>{{ item.count }}</h3>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="(this.totalLeadCount > 0)" class="mm-dash-cell">
        <h5 class="cell-title">Leads</h5>
        <div class="under-title-inner-cell">
            <div class="inner-cell-block">
                <h5>Total # Of Leads:</h5>
                <div class="campaign-totals-row">
                    <h3>{{ this.totalLeadCount }}</h3>
                </div>
            </div>
        </div>
        <div class="inner-cell-block">
            <p class="disclaimer-text">Lead metrics are displayed if REACH is actively collecting leads. If your brand does not permit the storage of personally identifiable information (PII), this will not be reflected in the system. Please use the Rollup Reporting widget for the most accurate campaign performance metrics.</p>
        </div>
    </div>
    <div *ngIf="!tooManyCompanies" class="mm-dash-cell">
        <h5 class="cell-title">Reporting</h5>
        <div class="under-title-inner-cell">
            <form [formGroup]="reportForm" (submit)="onFormSubmit()" class="mm-reporting-form">
                <div>
                    <div *ngIf="!isEmptyCompanyGuid" class="g-3 align-items-center mb-3">
                        <label class="col-6 mb-2">Company Name</label>
                        <div class="col-6 mb-2">{{ companyData.name }}</div>
                        <label class="col-6 mb-2">Platforms</label>
                        <div class="col-auto">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" value="All" name="platforms" formControlName="platforms"
                                    id="AllPlatforms" checked (change)="handlePlatformsChange($event, 'All')">
                                <label class="form-check-label" for="AllPlatforms">
                                    All
                                </label>
                            </div>
                            <div *ngFor="let platform of platforms" class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [value]="platform" name="platforms"
                                    formControlName="platforms" id="platform_{{ platform }}"
                                    (change)="handlePlatformsChange($event, platform)">
                                <label class="form-check-label" for="platform_{{ platform }}">
                                    {{ platform }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mb-3">
                        <label class="col-12 mb-2">Please select report period</label>
                        <div class="col-auto" style="margin-top: 0px !important;">
                            <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)" #t>
                                <option selected disabled>Please select period.</option>
                                <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods"
                                    [selected]="selectedReportPeriod==p.key">
                                    {{ p.key }}
                                </option>
                            </select>
                        </div>
                        <label class="col-2 mb-2"></label>
                    </div>
                </div>
                <div class="col-auto">
                    <button type="submit" class="blue-button" style="width: 100% !important;">View Rollup Report</button>
                </div>
            </form>
        </div>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" style="width: 24% !important;">
        <li [ngbNavItem]="1">
        </li>
        <li [ngbNavItem]="3" style="width: 48% !important;">
            <ng-template ngbNavContent style="background-color: white;">
                <div class="la-ball-spin-clockwise la-dark la-3x" *ngIf="!loadCompleted">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div style="background-color: white; border-radius: 0.25rem;">
                    <h5 class="cell-title">Rollup Report</h5>
                    <iframe #iframe style="height: 99vh; padding: 8px;" width="100%" (load)="onFrameLoadComplete()"></iframe>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" style="background-color: transparent; width: 100%;"></div>
</div>
