import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../store/app.service';
import { TasksService } from '../../tasks/tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ILead } from '../../../core/models/lead.models';
import { ITask } from '../../../core/models/tasks.models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignsApiService } from '../campaigns.apiservice';
import { TasksApiService } from '../../tasks/tasks.apiservice';
import { LeadsApiService } from '../../leads/leads.apiservice';
import { IPermission } from '../../../core/models/auth.models';
import { getRolesForPage } from '../../../core/helpers/utility';
import { ICampaign } from '../../../core/models/campaigns.model';
import { OrdersApiService } from '../../orders/orders.apiservice';
import { ICampaignCheck } from '../../../core/models/campaigns.model';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SubscriptionApiService } from '../../subscriptions/subscription.apiservice';
import { CAMPAIGN_STAGES, PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { IColumn, LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AddCampaignCheckModal } from '../../../shared/modals/add-campaign-check/add-campaigncheck.component';


@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})

export class CampaignDetailsComponent implements OnInit, OnDestroy {

  orderColumns = [
    { title: 'ID', field: 'numericId', sortable: true, searchable: true },
    { title: 'From', field: 'startDate', sortable: true, searchable: true },
    { title: 'To', field: 'endDate', sortable: true, searchable: true },
    { title: 'Amount', field: 'amount', sortable: true, searchable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.0-2'] },
    { title: 'Status', field: 'status', sortable: true, searchable: true, type: 'html' },
    { title: 'Subscription ID', field: 'subscriptionNumericId', sortable: true, searchable: true, anchorLink: true, anchorType: 'subscription', anchorParamsField: 'subscriptionId' },
    { title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, pipe: 'cleanupCampaign' },
    { title: 'Generated On', field: 'generatedOn', sortable: true, searchable: true, pipe: 'P_AppDateFormat', pipeArgs: ['YYYY-MM-DD HH:mm'] },
  ]

  columns = [
    { title: 'Received At', field: 'createdAt', sortable: true, searchable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY HH:mm', true] },
    { title: 'First Name', field: 'firstName', sortable: true, searchable: true },
    { title: 'Last Name', field: 'lastName', sortable: true, searchable: true },
    { title: 'Email', field: 'email', sortable: true, searchable: true },
    { title: 'Phone', field: 'phone', sortable: true, searchable: true },
    { title: 'Campaign', field: 'campaignName', sortable: true, searchable: true },
    { title: 'Referal Source', field: 'referralSource', sortable: true, searchable: true },
  ]

  active: number = 1;
  dataTotalCount: number = 0;
  leadsTotalCount: number = 0;

  ordersData: Array<any> = [];
  mostRecentOrder: any = {};

  userId: string = '';

  savedSearchId!: string;
  
  doWhat: IPermission = {} as IPermission;
  campaignData: ICampaign = {} as ICampaign;

  isAdmin: boolean = false;
  isExternal: boolean = false;
  showTasks: boolean = true;
  isLoaded: boolean = false;
  showOnlyMyTasks: boolean = true;
  showCompletedTasks: boolean = false;

  private loadEvent!: LoadDataFromServer;
  
  
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public CAMPAIGN_STAGES = CAMPAIGN_STAGES;
  public ROLES = ROLES;
  public ROLES_TYPE = ROLES_TYPE;

  columnsTasks: Array<IColumn> =  new Array<IColumn>();
  completedTasksColumns: Array<IColumn> = new Array<IColumn>();
  subscriptions: Array<any> = new Array<any>();
  tasksData: Array<ITask> = new Array<ITask>();
  permTasksData: Array<ITask> = new Array<ITask>();
  completedTasksData: Array<ITask> = new Array<ITask>();
  leadsData: Array<ILead> = new Array<ILead>();
  permLeadsData: Array<ILead> = new Array<ILead>();

  roleOptions: any = {};
  
  userRoles: Array<number> = [];
  
  reportingPeriods: Array<IReportingPeriod> = [];

  iframeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

  constructor(
    private appService: AppService,
    private sanitizer: DomSanitizer,
    public uService: UtilityService,
    private route: ActivatedRoute,
    private tasksService: TasksService,
    private taskApiService: TasksApiService,
    private modalService: NgbModal,
    private campaignService: CampaignsApiService,
    private dataFeedService: DataFeedService,
    private leadsApi: LeadsApiService,
    private ordersApiService: OrdersApiService,
    private subscriptionService: SubscriptionApiService,
    public router: Router) {

    this.userRoles = [...this.uService.getUserRoles()];

    this.columnsTasks.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.columnsTasks.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.columnsTasks.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.columnsTasks.push({ title: 'Due Date', field: 'dueDate', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] } as IColumn);
    this.columnsTasks.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, visible: (this.userRoles.includes(ROLES.Admin.Code) || this.userRoles.includes(ROLES.TechServices.Code) || this.userRoles.includes(ROLES.DMS.Code) || this.userRoles.includes(ROLES.SalesRep.Code)) } as IColumn);

    this.completedTasksColumns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.completedTasksColumns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.completedTasksColumns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.completedTasksColumns.push({ title: 'Completed On', field: 'completedOn', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['YYYY/MM/DD HH:mm', true] } as IColumn);
    this.completedTasksColumns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, visible: (this.userRoles.includes(ROLES.Admin.Code) || this.userRoles.includes(ROLES.TechServices.Code) || this.userRoles.includes(ROLES.DMS.Code) || this.userRoles.includes(ROLES.SalesRep.Code)) } as IColumn);
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Campaign');

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        if (u.id !== undefined) {
          this.userId = u.id;
        }
        this.isExternal = u.type == ROLES_TYPE.External;
        var r = getRolesForPage(u.roles, PAGE_NAMES.Campaigns);
        this.isAdmin = r.isAdmin;
        if (this.isAdmin) {
          this.showOnlyMyTasks = false;
        }
        if (r.options) {
          this.roleOptions = JSON.parse(r.options!);
        }
      });

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.loadCampaignDetails(id);
    }

    this.configureTasksService();
  }

  loadCampaignDetails(id: string): void {
    this.campaignService.getCampaignDetails(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.campaignData = response.data;
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dms.reach.marketing/dmm/kpis.php?campaignID=${this.campaignData.id}&platform=${this.campaignData.platform}`);
        if (this.campaignData.subscriptionActive && !this.isExternal) {
          this.loadMostRecentOrder();
        } else {
          this.mostRecentOrder.status = '<label style="color:red">No Active Subscription</label>';
        }
      });
  }

  loadMostRecentOrder(): void {
    this.subscriptionService.getAllSubscriptions(this.campaignData.id, this.campaignData.clientId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.subscriptions) {
          const subscriptions = response.subscriptions.map((x: any) => {
            x.dms = x.campaigns[0].dms;
            x.salesrep = x.campaigns[0].salesRep;
            return x;
          });
          const activeSubscription: any = subscriptions.find((sub: any) => sub.status === 'Active');
          if (activeSubscription) {
            this.subscriptionService.getSubscription(activeSubscription.id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(
                (response: any) => {
                  if (response && response.subscription.orders) {
                    const sortedOrders: Array<{ generatedOn: string; paid: boolean; isActive: boolean; refundText: string; status?: string }> = response.subscription.orders.sort((a: { generatedOn: string }, b: { generatedOn: string }) => new Date(b.generatedOn).getTime() - new Date(a.generatedOn).getTime());
                    
                    this.mostRecentOrder = sortedOrders[0];
                    this.mostRecentOrder.status = this.mostRecentOrder.paid ? '<label style="color: green !important;">Paid ' + this.mostRecentOrder.refundText + '</label>' : this.mostRecentOrder.isActive ? '<label style="color:red">Not Paid</label>' : '<label style="color:red">Canceled</label>';
                  }
                });
          }
        }
      });
  }

  loadTasksData(id: string): void {
    this.taskApiService.getTasksByCampaign(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.permTasksData = response.data;
        this.tasksData = this.permTasksData.filter((x: ITask) => (x.isCompleted === null || x.isCompleted === false) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true));
        this.completedTasksData = this.permTasksData.filter((x: ITask) => (x.isCompleted && x.isCompleted === true) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true));
      });
  }

  filterLeads() {
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Leads);
    this.leadsApi.getAllLeads({search: this.campaignData.campaignId})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.leads) {
          this.permLeadsData = [...response.leads].filter((x: ILead) => x.campaignId == this.campaignData.id);
          this.leadsData = this.permLeadsData;
          if (!this.isLoaded) {
            this.leadsTotalCount = this.leadsData.length;
            this.isLoaded = true;
          }
        }
    });
  }

  configureTasksService() {
    this.tasksService.onSave.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.refreshTasksData();
        }
      });
    this.tasksService.onDelete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.refreshTasksData();
        }
      });
    this.tasksService.onMarkAsComplete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.refreshTasksData();
        }
      });
      this.tasksService.onReloadTaskData.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.refreshTasksData();
        }
      });
  }

  refreshTasksData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.loadTasksData(id);
    }
  }

  ngAfterViewInit() {}

  onTaskRowClick(evt: any) {
    this.tasksService.openEditTaskModal(evt, this.isAdmin || this.roleOptions.tasks.canEdit, this.isAdmin || this.roleOptions.tasks.canDelete);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onAddTask() {
    this.tasksService.openEditTaskModal({id: null, clientId: this.campaignData.clientId, campaignId: this.campaignData.id}, this.isAdmin || this.roleOptions.tasks.canEdit, this.isAdmin || this.roleOptions.tasks.canDelete);
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    if ((event.search != "") && (event.search != null)) {
      this.leadsData = this.permLeadsData.filter((x: ILead) => x.firstName.toLowerCase().includes(event.search.toLowerCase()) || x.lastName.toLowerCase().includes(event.search.toLowerCase()) || x.email.toLowerCase().includes(event.search.toLowerCase()) || x.phone.toLowerCase().includes(event.search.toLowerCase()) || x.campaignName.toLowerCase().includes(event.search.toLowerCase()) || x.referralSource.toLowerCase().includes(event.search.toLowerCase()));
      this.leadsTotalCount = this.leadsData.length;
      this.leadsData = this.leadsData.slice((event.pageNumber - 1) * Number(event.pageSize), event.pageNumber * Number(event.pageSize));
    }
    else {
      this.leadsData = this.permLeadsData.slice((event.pageNumber - 1) * Number(event.pageSize), event.pageNumber * Number(event.pageSize));
      this.leadsTotalCount = this.permLeadsData.length;
    }
  }

  onDelete(event: any) {
    this.leadsApi.deleteLead(event)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllLeads({
          SavedSearchId: this.savedSearchId,
          ...this.loadEvent
        });
      })
  }

  getRowLink(row: any) {
    return `/pages/lead-details/${row.id}`;
  }
  
  loadOrders(): void {
    this.ordersData = [];
    this.subscriptionService.getAllSubscriptions(this.campaignData.id, this.campaignData.clientId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.subscriptions) {
          response.subscriptions.forEach((subscription: any) => {
            this.subscriptionService.getSubscription(subscription.id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe((response: any) => {
                if (response && response.subscription.orders) {
                  response.subscription.orders.forEach((order: any) => {
                    order.status = order.paid ? '<label style="color:green">Paid ' + order.refundText + '</label>' : (order.isActive ? '<label style="color:red">Not Paid</label>' : '<label style="color:red">Canceled</label>');
                    order.subscriptionNumericId = subscription.numericId;
                    order.subscriptionId = subscription.id;
                    order.campaignName = subscription.campaigns[0].campaignName;
                    this.ordersData.push(order);
                  });
                  this.dataTotalCount = this.ordersData.length;
                }
              });
          });
        }
      });
  }

  onClick(event: any) {
    this.router.navigate(['/pages/orders/details', event.id]);
  }

  changeShowTasks() {
    if (this.showCompletedTasks) {
      this.showTasks = true;
      this.showCompletedTasks = false;
    }
  }
  
  changeShowCompletedTasks() {
    if (this.showTasks) {
      this.completedTasksData = this.permTasksData.filter((x: ITask) => (x.isCompleted && x.isCompleted === true) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true));
      this.showTasks = false;
      this.showCompletedTasks = true;
    }
  }

  onShowMyTasksChange() {
    this.refreshTasksData();
  }

  onTasksTabClick(): void {
    this.refreshTasksData();
  }

  onCampaignCheck(){
    const dialogRef = this.modalService.open(AddCampaignCheckModal);
    dialogRef.componentInstance.iActiveUserExternal = this.isExternal;
    dialogRef.componentInstance.isIsAdmin = this.isAdmin;
    dialogRef.componentInstance.iCampaignId = this.campaignData.id;
    dialogRef.componentInstance.iType = 1;
    dialogRef.componentInstance.isGooglePlatform = this.campaignData.platform == 'Google' ? true : false;

    const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: ICampaignCheck) => {

      this.dataFeedService.saveCampaignCheck(r)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          dialogRef.dismiss();
          this.refreshTasksData();
          this.appService.fetchAllNotes(this.campaignData.id, 1);
        })
      dialogSubmitSubscription.unsubscribe();
    })
  }

}
