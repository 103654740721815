<div class="container-fluid p-3">
  <div class="col-12">
    <div class="reportFrame">
      <div class="formFrame">
        <div class="reportForm">
          <form [formGroup]="reportForm" (ngSubmit)="updateReport()">
            <!-- Show selector if there are multiple companies -->
            <ng-container *ngIf="companiesData.length > 1; else singleCompany">
              <div class="form groupHeader">
                  <label>Select Company:</label>
              </div>
              <div class="form dropdown">
                  <select class="form-select mb-2" aria-label="Please select a Company" formControlName="companySelector" [ngClass]="{ 'is-invalid': reportForm.controls.companySelector.touched && reportForm.controls.companySelector.errors }">
                      <option value="" disabled selected>Please select a Company</option>
                      <option *ngFor="let company of companiesData" [value]="company.id">
                          {{ company.name }}
                      </option>
                  </select>
                  <div *ngIf="reportForm.controls.companySelector.touched && reportForm.controls.companySelector.errors" class="invalid-feedback">
                    <div *ngIf="reportForm.controls.companySelector.errors.required">Please select a Company.</div>
                    </div>
              </div>
            </ng-container>
            <ng-template #singleCompany>
              <input type="hidden" formControlName="companySelector" [value]="selectedCompanyId">
            </ng-template>

              <div>
                  <div class="formGroup dateSelect">
                      <div class="form groupHeader">
                          <label>Please select report period:</label>
                      </div>
                      <div class="form dropdown">
                          <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)" #t>
                              <option selected disabled>Please select period.</option>
                              <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods" [selected]="selectedPeriod==p.key">
                                  {{ p.key }}
                              </option>
                          </select>
                      </div>
                      <div class="dateRange">
                          <div class="form dateInput">
                              <label class="sr-only" for="startDate">Start</label>
                              <input type="date" [readonly]="datesReadonly" formControlName="startDate"
                              class="form-control form-date date-custom" [ngClass]="{ 'is-invalid': reportForm.controls.startDate.errors }" />
                              <div *ngIf="reportForm.controls.startDate.errors" class="invalid-feedback">
                              <div *ngIf="reportForm.controls.startDate.errors.required">Please select period.</div>
                              </div>
                          </div>

                          <div class="form dateInput">
                              <span style="margin: 0 6px;">&mdash;</span>
                          </div>

                          <div class="form dateInput">
                              <label class="sr-only" for="stopDate">End</label>
                              <input type="date" [readonly]="datesReadonly" formControlName="stopDate"
                              class="form-control form-date date-custom" [ngClass]="{ 'is-invalid': reportForm.controls.stopDate.errors }" />
                              <div *ngIf="reportForm.controls.stopDate.errors" class="invalid-feedback">
                              <div *ngIf="reportForm.controls.stopDate.errors.required">Please select period.</div>
                              </div>
                          </div>
                    </div>
                  </div>
                  <div class="formGroup">
                      <div class="form submit">
                          <button type="submit" class="btn btn-primary mb-2">Update</button>
                      </div>
                  </div>
              </div>
          </form>
      </div>
      </div>
      <div class="reportData">
        <ng-container *ngIf="loading; else dataContent">
          <div class="loading">
            <div class="la-ball-spin-clockwise la-2x">
              <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
          </div>
        </ng-container>
        <ng-template #dataContent>
          <ng-container *ngIf="companyMetaData.length || companyGoogleData.length">
            <h2>{{ getActiveCompanyName() }}</h2>
            <strong>These numbers are for quick reference and can differ slightly from the live dashboard reports.</strong>
            <br>
            <em>*Monthly budget is an estimate, calculated based on the spend over the date range selected.</em>
            <ul style="margin-top: 1em;" class="nav nav-tabs" id="reportTabs" role="tablist">
              <li class="nav-item active" id="meta-data-tab" (click)="activeTab = 'meta'" [class.active]="activeTab === 'meta'" role="tab" aria-controls="meta-data" aria-selected="true">
                <a class="nav-link">Meta</a>
              </li>
              <li class="nav-item" id="google-data-tab" (click)="activeTab = 'google'" [class.active]="activeTab === 'google'" role="tab" aria-controls="google-data" aria-selected="false" style="margin-left: -10px;">
                <a class="nav-link">Google</a>
              </li>
            </ul>
            <div class="tab-content" id="reportTabsContent">
              <div class="tab-pane fade" [class.show]="activeTab === 'meta'" [class.active]="activeTab === 'meta'" id="meta-data" role="tabpanel" aria-labelledby="meta-data-tab">
                <div style="padding-top: 10px;">
                  <app-report-datatable 
                    [iSortColumn]="'campaign_name'"
                    [iSortDirection]="'asc'"
                    [iColumns]="defaultMetaColumns" 
                    [iData$]="companyMetaData"
                    [iTotalCount]="companyMetaData.length"
                    [iShowFooter]="companyMetaData.length > defaultPageSize"
                    [iPageSize]="defaultPageSize"
                    [iTotalData]="companyMetaTotals"
                    [iAverageData]="companyMetaAverages"
                    [iReportTitle]="'meta'"
                  >
                  </app-report-datatable>
                </div>
              </div>
              <div class="tab-pane fade" [class.show]="activeTab === 'google'" [class.active]="activeTab === 'google'" id="google-data" role="tabpanel" aria-labelledby="google-data-tab">
                <div style="padding-top: 10px;">
                  <app-report-datatable 
                    [iSortColumn]="'campaign_name'"
                    [iSortDirection]="'asc'"
                    [iColumns]="defaultGoogleColumns" 
                    [iData$]="companyGoogleData"
                    [iTotalCount]="companyGoogleData.length"
                    [iShowFooter]="companyGoogleData.length > defaultPageSize"
                    [iPageSize]="defaultPageSize"
                    [iTotalData]="companyGoogleTotals"
                    [iAverageData]="companyGoogleAverages"
                    [iReportTitle]="'google'"
                  >
                  </app-report-datatable>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>

      </div>
    </div>
  </div>
</div>